<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <HeaderNew :pageLoading="loader" :isSticky="stickyVal" />
  <!-- <Header /> -->
  <router-view @page-loading="(val) => (loader = val)" @set-sticky="(val) => (stickyVal = val)" />
  <FooterNew />
  <!-- <Footer /> -->
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HeaderNew from "@/components/HeaderNew.vue";
import FooterNew from "@/components/FooterNew.vue";

export default {
  components: {
    Header,
    Footer,
    HeaderNew,
    FooterNew,
  },
  data() {
    return {
      loader: true,
      stickyVal: false,
    };
  },
};
</script>
<style></style>
