<template>
  <section class="storysection wf-section">
    <div class="container-2">
      <h1 class="centered-heading left-bordered">
        <span class="highlightheading">Donation</span>
      </h1>

      <div class="mt-3 donation-card-wrapper">
        <div class="text-center donation-card-heading" v-if="showInfo">
          <button
            class="back-btn"
            style=""
            tabindex="4"
            @click="showInfo = false"
          >
            <i class="light-icon-chevron-left"></i>
          </button>
          Add Your Information
        </div>
        <div class="text-center donation-card-heading" v-else>Select</div>
        <div class="donation-card-details">
          <div class="donation-card-quick" :class="showInfo && 'd-none'">
            <form class="d-flex flex-column h-100" @submit.prevent="Next(obj)">
              <h5>Choose, enter or select a donation method</h5>

              <div class="mb-3">
                <label for="type"
                  >How often would you like to give?
                  <span class="text-danger">*</span></label
                >
                <select
                  class="custom-select form-control d-block w-100"
                  v-model="otherObj.recurring"
                  id="type"
                  required=""
                >
                  <option value="One Time">One Time</option>
                  <option value="Monthly">Monthly</option>
                </select>
                <div class="invalid-feedback">Please select a valid type.</div>
              </div>

              <div class="input-group quick-input">
                <div class="input-group-prepend">
                  <span class="input-group-text">£</span>
                </div>
                <input
                  :ref="'amount'"
                  type="number"
                  v-model="obj.amount"
                  required
                  class="form-control"
                  @focus="obj.selected = 'Custom'"
                />
              </div>
              <div class="quick-button-wrapper">
                <div
                  v-for="(item, ind) in getQuickData(obj.initialAmount)"
                  :key="item"
                  class="quick-button"
                  :class="obj.selected == item && 'selected'"
                  @click="setAmount(obj, item)"
                >
                  <sup v-if="ind != 5">£</sup>{{ item }}
                </div>
              </div>

              <div class="d-flex justify-content-center mt-auto">
                <button
                  class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                  type="submit"
                >
                  Continue <i class="light-icon-chevron-right"></i>
                </button>
              </div>
            </form>
          </div>
          <div class="donation-card-info" :class="showInfo && 'add-animation'">
            <form @submit.prevent="formSubmit">
              <div class="">
                <h5>We’ll never share this information with anyone.</h5>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="firstName"
                      >First name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="firstName"
                      placeholder=""
                      v-model="otherObj.firstName"
                      required=""
                    />
                    <div class="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="lastName"
                      >Last name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder=""
                      v-model="otherObj.lastName"
                      required=""
                    />
                    <div class="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>
                </div>

                <div class="mb-2">
                  <label for="email"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder=""
                    required=""
                    v-model="otherObj.email"
                  />
                  <div class="invalid-feedback">
                    Please enter a valid email address
                  </div>
                </div>
                <div class="mb-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="otherObj.anonymous"
                    id="anonymous"
                  />
                  <label class="form-check-label mx-1" for="anonymous">
                    Make this an anonymous donation
                  </label>
                </div>

                <div class="mb-3">
                  <label for="type"
                    >Donation Type <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-select form-control d-block w-100"
                    v-model="otherObj.donationType"
                    id="type"
                    required=""
                  >
                    <option v-for="dt in typeOptions" :key="dt" :value="dt">
                      {{ dt }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    Please select a valid type.
                  </div>
                </div>

                <div class="mb-3">
                  <label for="cc-name"
                    >Name on card <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="cc-name"
                    placeholder=""
                    v-model="otherObj.cardName"
                    required=""
                  />

                  <div class="invalid-feedback">Name on card is required</div>
                </div>

                <div id="card-element"></div>
                <div class="mb-3 mt-2">
                  <label for="address">Address</label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="your address"
                    required=""
                    v-model="otherObj.address"
                  />
                  <div class="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                </div>
                <div class="mb-2">
                  <label>
                    <img
                      src="@/assets/images/worldappeal/gift-aid-logo.png"
                      alt=""
                      class="gift-aid-image"
                    />
                    <b>Add 25% more to your donation at no cost to you.</b> A
                    Gift Aid declaration allows to claim tax back on eligible
                    donations. It means that for every £1 you donate to we can
                    claim back 25p, at no extra cost to you.
                  </label>
                </div>
                <div class="mb-1 row">
                  <div class="col-md-4">
                    <PieChart
                      :value="{
                        label: [`Donation (£${obj.amount})`],
                        perc: [100],
                        colors: ['#3367b7'],
                      }"
                    />
                  </div>
                  <div class="col-md-4">
                    <PieChart
                      :value="{
                        label: [`Gift Aid (£25)`, `Donation (£${obj.amount})`],
                        perc: [25, 75],
                        colors: ['#4aa567', '#3367b7'],
                      }"
                    />
                  </div>
                  <div class="col-md-4">
                    <PieChart
                      :value="{
                        label: ['Total'],
                        perc: [100],
                        colors: ['#4aa567'],
                      }"
                    />
                  </div>
                </div>
                <div class="mb-1">
                  <input
                    class="form-check-input gift-aid-checkbox"
                    type="checkbox"
                    v-model="otherObj.giftAid"
                    id="gift-aid"
                  />
                  <label class="form-check-label gift-aid-label" for="gift-aid">
                    Yes, I would like to claim Gift Aid
                  </label>
                </div>
                <small class="text-muted">
                  By ticking the "Yes" box, I agree I would like to reclaim the
                  tax on all qualifying donations I have made, as well as any
                  future donations, until I notify them otherwise. I understand
                  that if I pay less Income Tax and/or Capital Gains Tax than
                  the amount of Gift Aid claimed on all my donations in that tax
                  year I may be asked to pay any difference. I understand that
                  will reclaim 25p of tax on every £1 that I give.
                </small>
              </div>
              <div id="card-error" class="text-danger mt-3"></div>

              <div class="d-flex justify-content-center mt-3">
                <button
                  class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                  type="submit"
                  :disabled="processing"
                >
                  Donate Now (£{{ obj.amount }})
                  <div
                    class="custom-mini-loader"
                    v-if="processing"
                    style="margin-left: 10px"
                  ></div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import PieChart from "@/components/PieChart.vue";

export default {
  name: "Donation",
  components: { PieChart },
  data() {
    return {
      valuetest: 30,
      obj: {
        title: "Fund",
        initialAmount: 50,
        amount: 50,
        selected: 50,
      },
      otherObj: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        donationType: "General Donation",
        cardName: "",
        recurring: "One Time",
        anonymous: false,
        address: "",
        giftAid: false,
      },
      showInfo: false,
    };
  },
  created() {
    this.$emit("set-sticky", true);
  },
  methods: {
    getQuickData(val) {
      var result = [];
      for (var i = 1; i <= 5; i++) {
        result.push(val * i);
      }
      result.push("Custom");
      return result;
    },
    setAmount(item, val) {
      item.selected = val;
      if (val == "Custom") {
        this.$refs.amount.focus();
      } else item.amount = val;
    },
    Next(item) {
      if (item.amount <= 0) {
        alert("Please enter correct amount.");
      } else {
        this.showInfo = true;
      }
      console.log(item);
    },
    formSubmit() {
      console.log(this.otherObj);
    },
  },
};
</script>
<style></style>
