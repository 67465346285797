<template>
  <div
    class="navbar-no-shadow wf-section"
    :class="{
      'black-navbar':
        pageLoading ||
        isSticky ||
        scrollPosition > 50 ||
        isOpen ||
        $route.params.id == 7 ||
        ['not-found', 'privacy'].includes(this.$route.name),
      'sticky-navbar': isSticky || $route.params.id == 7,
    }"
  >
    <!-- 'white-navbar' -->
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navbar-no-shadow-container w-nav"
    >
      <div class="container-regular">
        <div class="navbar-wrapper">
          <a class="navbar-brand w-nav-brand" href="/">
            <img
              v-if="logoData"
              :src="logoData.logo"
              loading="lazy"
              width="76"
              sizes="(max-width: 479px) 100vw, 100px"
              alt=""
              class="logoimage"
            />
            <img
              v-else
              src="@/assets/images/world-appeal-white.png"
              loading="lazy"
              width="76"
              sizes="(max-width: 479px) 100vw, 100px"
              srcset="
                @/assets/images/world-appeal-white.png 500w,
                @/assets/images/world-appeal-white.png 578w
              "
              alt=""
              class="logoimage"
            />
          </a>
          <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
            <ul role="list" class="nav-menu w-list-unstyled">
              <li
                v-for="(item, index) in menuData"
                :key="item.menu.id"
                :class="{
                  dropdown:
                    item.submenu.length > 0 || item.menu.actionType == 'blogs',
                }"
              >
                <div
                  v-if="
                    item.submenu.length > 0 || item.menu.actionType == 'blogs'
                  "
                  :data-hover="screenSize"
                  data-delay="0"
                  class="nav-dropdown w-dropdown my-dropdown"
                  @mouseover="
                    screenSize == true
                      ? (showStatus[index] = true)
                      : (showStatus[index] = false)
                  "
                  @mouseleave="showStatus[index] = false"
                >
                  <div class="nav-dropdown-toggle w-dropdown-toggle">
                    <a
                      :href="getRoute(item.menu)"
                      :target="
                        item.menu.actionType == 'url' ? '_blank' : '_self'
                      "
                      @click="setMenu"
                    >
                      <div class="text-block-2">{{ item.menu.name }}</div>
                    </a>
                    <!-- <router-link
                      :to="getRoute(item.menu)"
                      :target="item.menu.actionType == 'url' ? '_blank' : '_self'"
                      @click="setMenu"
                    >
                      <div class="text-block-2">{{ item.menu.name }}</div>
                    </router-link> -->
                    <div
                      class="icon-5 w-icon-dropdown-toggle"
                      @click="showStatus[index] = !showStatus[index]"
                    ></div>
                  </div>
                  <nav
                    class="nav-dropdown-list shadow-three mobile-shadow-hide w-dropdown-list"
                    :class="{ 'w--open': showStatus[index] }"
                  >
                    <div class="div-block-2">
                      <a
                        v-if="item.menu.actionType == 'blogs'"
                        v-for="bb in blogs"
                        :key="bb.id"
                        :href="'/blog?id=' + bb.id"
                        target="_self"
                        class="nav-dropdown-link w-dropdown-link"
                      >
                        {{ bb.title }}
                      </a>
                      <a
                        v-else
                        v-for="sub in item.submenu"
                        :key="sub.id"
                        :href="getRoute(sub)"
                        :target="sub.actionType == 'url' ? '_blank' : '_self'"
                        class="nav-dropdown-link w-dropdown-link"
                      >
                        {{ sub.name }}
                      </a>
                      <!-- <router-link
                        v-for="sub in item.submenu"
                        :key="sub.id"
                        :to="getRoute(sub)"
                        :target="sub.actionType == 'url' ? '_blank' : '_self'"
                        class="nav-dropdown-link w-dropdown-link"
                      >
                        {{ sub.name }}
                      </router-link> -->
                    </div>
                  </nav>
                </div>

                <a
                  v-else
                  :href="getRoute(item.menu)"
                  :target="item.menu.actionType == 'url' ? '_blank' : '_self'"
                  class="nav-link"
                  @click="setMenu"
                >
                  {{ item.menu.name }}
                </a>
                <!-- <router-link
                  v-else
                  :to="getRoute(item.menu)"
                  :target="item.menu.actionType == 'url' ? '_blank' : '_self'"
                  class="nav-link"
                  @click="setMenu"
                >
                  {{ item.menu.name }}
                </router-link> -->
              </li>
              <li class="mobile-margin-top-10">
                <div class="nav-button-wrapper">
                  <!-- @click="toggleDonate('open')" -->
                  <!-- href="https://buy.stripe.com/fZe28xa4S2jR2qcfZf"
                  target="_blank" -->
                  <a
                    data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2"
                    :href="
                      $route.params.id == 7
                        ? 'https://qurbanigiving.com/charities/world-appeal/animal'
                        : '/page/21'
                    "
                    class="button-primary w-button"
                    >Donate Now</a
                  >
                  <!-- @click="setButton" -->
                  <!-- <a
                    href="https://docs.google.com/forms/d/14QlffvQwKvB10HIi6EY_NS-sqWyBGNv4xuB9OWim9hU/edit"
                    target="_blank"
                    class="button-primary-white w-button mobile-view-button"
                    >Volunteer Now</a
                  > -->
                </div>
              </li>
            </ul>
          </nav>

          <div class="menu-button w-nav-button" @click="setButton">
            <div
              class="icon w-icon-nav-menu"
              :class="{ 'my-content set-fontsize': isOpen }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="donatepopup wf-section" @click="toggleDonate('close')">
    <div class="donationWrapper">
      <h1 style="color: white; font-size: 30px" @click.stop>Account Details</h1>
      <div class="donationdiv" @click.stop>
        <h3 class="donationheading">Bank Name</h3>
        <h4 class="donationdetail">{{ bankObj.name }}</h4>
        <h3 class="donationheading">Title of Account</h3>
        <h4 class="donationdetail">{{ bankObj.title }}</h4>
        <h3 class="donationheading">Account Number</h3>
        <div style="display: flex; justify-content: space-between">
          <h4 class="donationdetail" id="account">{{ bankObj.account }}</h4>
          <img
            @click="copy('account')"
            width="20"
            height="20"
            src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
            alt="copy--v1"
            style="cursor: pointer"
          />
        </div>
        <h3 class="donationheading">Swift Code</h3>
        <div style="display: flex; justify-content: space-between">
          <h4 class="donationdetail" id="swift">{{ bankObj.swift }}</h4>
          <img
            @click="copy('swift')"
            width="20"
            height="20"
            src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
            alt="copy--v1"
            style="cursor: pointer"
          />
        </div>
        <h3 class="donationheading">Branch Code</h3>
        <div style="display: flex; justify-content: space-between">
          <h4 class="donationdetail" id="branch">{{ bankObj.branchCode }}</h4>
          <img
            @click="copy('branch')"
            width="20"
            height="20"
            src="https://img.icons8.com/pastel-glyph/64/copy--v1.png"
            alt="copy--v1"
            style="cursor: pointer"
          />
        </div>
      </div>
    </div>

    <a v-if="showOnline" class="button-primary w-button online-donate"
      >Donate Online</a
    >
    <!-- href="https://www.muslimgiving.org/justiceforaafia"
      target="_blank" -->
    <div data-w-id="44567913-62ce-5393-d531-c4ee0782d899" class="closeicon">
      X
    </div>
  </section>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      scrollPosition: null,
      isOpen: false,
      newsVisible: false,
      articleVisible: false,
      bankObj: {
        name: "United Bank Limited",
        title: "Word Appeal",
        account: "PK31 010xxxxxxx",
        swift: "worldappeal",
        branchCode: "0000",
      },
      showOnline: true,
      expireDate: "2023-08-01",
      menuData: [],
      showStatus: [],
      dataLoading: false,
      showMore: false,
      loading2: false,
      logoData: null,
      blogs: [],
    };
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
    },
    isSticky: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    screenSize() {
      return window.innerWidth > 991;
    },
  },
  beforeMount() {
    this.loadMenu();
    this.LoadLogo();
    this.loadBlogs();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    // console.log(this.$route, this.$route.name);
    // online donation link visibility
    // let date = new Date();
    // if (date >= new Date(this.expireDate)) this.showOnline = false;
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      // console.log(this.scrollPosition);

      const element = document.getElementById("news");
      if (element) {
        const rect = element.getBoundingClientRect();

        this.newsVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);
      }

      const el = document.getElementById("articles");
      if (el) {
        const rect2 = el.getBoundingClientRect();

        this.articleVisible =
          rect2.top >= 0 &&
          rect2.left >= 0 &&
          rect2.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect2.right <=
            (window.innerWidth || document.documentElement.clientWidth);
      }
    },

    copy(type) {
      let num = document.getElementById(type).innerText;
      navigator.clipboard.writeText(num);
      this.toast.success("Copied!");
    },
    setButton() {
      this.isOpen = !this.isOpen;
      document.body.style.overflow = this.isOpen ? "hidden" : "auto";
    },

    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";

      // to adjust nav-menu
      this.isOpen = false;
      document.body.style.overflow = "auto";
    },

    setMenu() {
      if (window.innerWidth < 991) {
        const btn = document.querySelector(".menu-button.w-nav-button");
        btn.click();
      }
    },

    move() {
      // console.log(location);
      location.assign(`${location.origin}/single-article?id=1`);
    },

    getRoute(item) {
      return item.actionType == "url"
        ? item.url
        : item.actionType == "page" && item.pageID != this.$route.params.id
        ? `/page/${item.pageID}`
        : "#";
      // : `/page/${this.$route.params.id}`;
    },
    loadMenu() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Menu/LoadWeb",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(this.menuData);
          this.menuData = response.data.data.filter(
            (el) => el.menu.status == "active"
          );
          this.showStatus = this.menuData.map((el) => false);
          // console.log(this.showStatus);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadLogo() {
      this.loading2 = true;
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Logos",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.logoData = res.data.data.find((el) => el.type == "header");
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.loading2 = false));
    },
    loadBlogs() {
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Blogs",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          this.blogs = res.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
