<template>
  <div class="home">
    <LvDialog
      header=""
      v-model="displayBasic"
      :style="{ maxWidth: '700px', width: '100%' }"
    >
      <template #header>
        <div>
          <h2 class="mb-0 detail-text">
            {{ `£${getAmount(this.displayItem)}` }}
          </h2>

          <!-- <h3 class="mb-1">{{ displayItem.title }}</h3>
          <h5 class="mb-0 detail-text">
            {{
              `£${displayItem.amount} x ${displayItem.value} = £${
                displayItem.amount * displayItem.value
              }`
            }}
          </h5> -->
        </div>
      </template>
      <div class="">
        <!-- <h5 class="mb-3">Payment Details</h5> -->

        <div class="row">
          <div class="col-md-12">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="firstName"
                      >First name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="firstName"
                      placeholder=""
                      v-model="paymentObj.firstName"
                      required=""
                    />
                    <div class="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="lastName"
                      >Last name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder=""
                      v-model="paymentObj.lastName"
                      required=""
                    />
                    <div class="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="email"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder=""
                      required=""
                      v-model="paymentObj.email"
                    />
                    <div class="invalid-feedback">
                      Please enter a valid email address
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="contact"
                      >Contact <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="contact"
                      placeholder=""
                      required=""
                      v-model="paymentObj.contact"
                    />
                    <div class="invalid-feedback">
                      Please enter a valid contact
                    </div>
                  </div>
                </div>

                <div class="mb-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="paymentObj.anonymous"
                    id="anonymous"
                  />
                  <label class="form-check-label mx-1" for="anonymous">
                    Make this an anonymous donation
                  </label>
                </div>

                <div class="mb-3">
                  <label for="type"
                    >Donation Type <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-select form-control d-block w-100"
                    v-model="paymentObj.donationType"
                    id="type"
                    required=""
                  >
                    <option v-for="dt in typeOptions" :key="dt" :value="dt">
                      {{ dt }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    Please select a valid type.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="country"
                    >Country <span class="text-danger">*</span></label
                  >
                  <select
                    id="country"
                    class="form-control"
                    required=""
                    v-model="paymentObj.country"
                    @change="countryChangeP"
                  >
                    <option
                      v-for="dt in countries"
                      :key="dt.name"
                      :value="dt.value"
                    >
                      {{ dt.name }}
                    </option>
                  </select>
                </div>
                <div class="row" v-if="paymentObj.country == 'United Kingdom'">
                  <div class="col-md-10 mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="e.g. Manor Farm Barns, Framingham Pigot or NR14 7PZ"
                      v-model.trim="searchQuery"
                    />
                  </div>
                  <div class="col-md-2 mb-2">
                    <button
                      class="btn btn-warning"
                      :disabled="searching"
                      @click="searchAddress"
                    >
                      Search
                    </button>
                  </div>
                  <div v-if="searching" class="mb-2">
                    Searching addresses...
                  </div>
                  <div class="col-md-12 mb-3" v-if="suggestionList.length > 0">
                    <select
                      class="form-control"
                      v-model="suggestedAddress"
                      @change="setAddressP"
                    >
                      <option value="" hidden>Select an address</option>
                      <option
                        v-for="dt in suggestionList"
                        :key="dt.summaryline"
                        :value="dt"
                      >
                        {{ dt.summaryline }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="address"
                    >Address <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="Address Line 1 *"
                    required=""
                    v-model="paymentObj.address.addressline1"
                    :disabled="paymentObj.country == 'United Kingdom'"
                  />
                </div>
                <div class="mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2 *"
                    required=""
                    v-model="paymentObj.address.addressline2"
                    :disabled="paymentObj.country == 'United Kingdom'"
                  />
                </div>
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City *"
                      required=""
                      v-model="paymentObj.address.posttown"
                      :disabled="paymentObj.country == 'United Kingdom'"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="State / Province / Region *"
                      required=""
                      v-model="paymentObj.address.county"
                      :disabled="paymentObj.country == 'United Kingdom'"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Postal / Zipcode *"
                      required=""
                      v-model="paymentObj.address.postcode"
                      :disabled="paymentObj.country == 'United Kingdom'"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-2">
                    I would like to be kept up to date with feedback about my
                    donations and news updates by:
                  </div>
                  <div class="col-md-3 mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="paymentObj.whatsapp"
                      id="whatsapp"
                    />
                    <label class="form-check-label mx-1" for="whatsapp">
                      Whatsapp
                    </label>
                  </div>
                  <div class="col-md-3 mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="paymentObj.post"
                      id="post"
                    />
                    <label class="form-check-label mx-1" for="post">
                      Post
                    </label>
                  </div>
                  <div class="col-md-3 mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="paymentObj.emailCheck"
                      id="emailCheck"
                    />
                    <label class="form-check-label mx-1" for="emailCheck">
                      Email
                    </label>
                  </div>
                  <div class="col-md-3 mb-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="paymentObj.sms"
                      id="sms"
                    />
                    <label class="form-check-label mx-1" for="sms"> SMS </label>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="cc-name"
                    >Name on card <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="cc-name"
                    placeholder=""
                    v-model="paymentObj.cardName"
                    required=""
                  />

                  <div class="invalid-feedback">Name on card is required</div>
                </div>

                <div id="card-element"></div>

                <template v-if="paymentObj.country == 'United Kingdom'">
                  <div class="mb-2 mt-2">
                    <label>
                      <img
                        src="@/assets/images/worldappeal/gift-aid-logo.png"
                        alt=""
                        class="gift-aid-image"
                      />
                      <b>Add 25% more to your donation at no cost to you.</b> A
                      Gift Aid declaration allows to claim tax back on eligible
                      donations. It means that for every £1 you donate to we can
                      claim back 25p, at no extra cost to you.
                    </label>
                  </div>
                  <div class="mb-1">
                    <PieChart :value="getChartValue(displayItem)" />
                  </div>
                  <div class="mb-1">
                    <input
                      class="form-check-input gift-aid-checkbox"
                      type="checkbox"
                      v-model="paymentObj.giftAid"
                      id="gift-aid"
                    />
                    <label
                      class="form-check-label gift-aid-label"
                      for="gift-aid"
                    >
                      Yes, I would like to claim Gift Aid
                    </label>
                  </div>
                  <small class="text-muted">
                    By ticking the "Yes" box, I agree I would like to reclaim
                    the tax on all qualifying donations I have made, as well as
                    any future donations, until I notify them otherwise. I
                    understand that if I pay less Income Tax and/or Capital
                    Gains Tax than the amount of Gift Aid claimed on all my
                    donations in that tax year I may be asked to pay any
                    difference. I understand that will reclaim 25p of tax on
                    every £1 that I give.
                  </small>
                </template>
              </div>
              <div id="card-error" class="text-danger mt-3"></div>

              <div class="d-flex justify-content-center mt-3">
                <button
                  class="btn btn-success btn-block d-flex align-items-center"
                  style="
                    border-radius: 50px;
                    font-size: 1.25rem;
                    padding: 12px 25px;
                  "
                  type="submit"
                  :disabled="processing"
                >
                  Donate Now
                  <div
                    class="custom-mini-loader"
                    v-if="processing"
                    style="margin-left: 10px"
                  ></div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- <template #footer>
        <LvButton label="No" icon="light-icon-x" @click="displayBasic = false" class="lv-button --text-button" />
        <LvButton label="Yes" icon="light-icon-check" @click="displayBasic = false" autofocus />
      </template> -->
    </LvDialog>

    <section
      class="donatepopup wf-section"
      id="donate-options"
      @click="toggleOptions('close', {})"
    >
      <div class="donationWrapper" @click.stop>
        <h1 style="color: white; font-size: 30px" @click.stop>
          Select Donation For
        </h1>
        <h3
          class="donationheading linkSelection"
          v-for="data in currentItem.moreItems"
          :key="data.title"
        >
          <a :href="data.link" target="_blank">
            {{ data.title }}
          </a>
        </h3>
      </div>
      <div data-w-id="44567913-62ce-5393-d531-c4ee0782d899" class="closeicon">
        X
      </div>
    </section>

    <section v-if="loading" class="hero-stack wf-section">
      <div class="custom-loader-wrapper">
        <div class="custom-loader"></div>
      </div>
    </section>
    <section class="hero-stack wf-section" v-else-if="pageData.length == 0">
      <div class="custom-loader-wrapper">
        <div class="notFound-div">
          <!-- <img src="@/assets/images/worldappeal/rocket.svg" alt="" width="60" /> -->
          <h1 style="margin: 0">Page Not Found</h1>
          <p>
            The page you're looking for does not exist or have been temporarily
            unavailable
          </p>
        </div>
      </div>
    </section>

    <template v-else v-for="(data, index) in pageData">
      <section class="storysection wf-section" v-if="false">
        <!-- v-if="$route.params.id == 7 && index == 2" -->
        <div class="container-2">
          <div class="row">
            <div
              class="col-lg-8 quick-donation-bg"
              :style="{
                'background-image':
                  'url(' + require('@/assets/images/worldappeal/qBg.jpg') + ')',
                'background-position': 'top',
              }"
            ></div>
            <div class="col-lg-4 quick-donation">
              <form
                class="d-flex flex-column justify-content-center h-100"
                @submit.prevent="openQLink"
              >
                <h2 class="quick-heading text-center">Donate For Qurbani</h2>

                <!-- <div class="mb-3 mt-3">
                  <label for="animal">Choose your animal</label>
                  <select
                    class="custom-select form-control d-block w-100"
                    v-model="animalSelected"
                    id="animal"
                    required=""
                  >
                    <option value="">Choose</option>
                    <option value="Small">Small</option>
                    <option value="Large">Large</option>
                    <option value="Shared">Shared</option>
                  </select>
                </div> -->

                <div class="d-flex justify-content-center mt-3">
                  <button
                    class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                  >
                    Donate Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Splide
        v-if="data.type == 'Hero'"
        id="main-slider"
        class="slider"
        :options="{
          drag: false,
          gap: 0,
          perPage: 1,
          perMove: 1,
          type: 'fade',
          arrows: false,
          pagination: false,
          autoplay: true,
          interval: 6000,
          pauseOnHover: false,
          easing: 'linear',
        }"
      >
        <SplideSlide>
          <div
            class="slide"
            :style="{
              'background-image': screenSize
                ? 'url(' + data.content.hero.mobileCover + ')'
                : 'url(' + data.content.hero.cover + ')',
              'background-position': data.content.hero.textRight
                ? '10% 0'
                : screenSize
                ? 'center'
                : 'center 0',
            }"
          >
            <div class="slidercontainer w-container">
              <div
                class="slidediv"
                :class="{ setRight: data.content.hero.textRight }"
              >
                <h1 class="bannerHeading" v-if="data.content.hero.title">
                  <span class="bg-colored">{{ data.content.hero.title }}</span>
                </h1>
                <h1 class="bannerHeading" v-if="data.content.hero.title2">
                  <span class="bg-colored">{{ data.content.hero.title2 }}</span>
                </h1>
                <a
                  v-if="data.content.hero.buttonTitle"
                  data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2"
                  :href="getRoute(data.content.hero, '')"
                  :target="
                    data.content.hero.actionType == 'url' ? '_blank' : '_self'
                  "
                  class="button-primary w-button bannerButton"
                  >{{ data.content.hero.buttonTitle }}</a
                >
              </div>
            </div>
          </div>
        </SplideSlide>
      </Splide>

      <section
        v-else-if="data.type == 'Projects'"
        class="storysection wf-section"
      >
        <div class="container-2" v-if="showSubs">
          <div class="sub-wrapper">
            <button class="back-button" @click="backSub()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                width="20"
                height="20"
                focusable="false"
              >
                <path
                  d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"
                ></path>
              </svg>
            </button>
            <h1 class="centered-heading">
              {{ getTitle(data.content.projectDetails) }}
            </h1>
          </div>

          <div
            v-if="subLoading"
            style="height: 200px"
            class="d-flex align-items-center justify-content-center"
          >
            <div class="custom-loader"></div>
          </div>
          <div
            v-else-if="currentData.length == 0"
            style="height: 200px"
            class="d-flex align-items-center justify-content-center"
          >
            <h1>No Projects to show</h1>
          </div>
          <Splide
            v-else
            class="storybottomslider"
            :options="{
              // rewind: false,
              omitEnd: true,
              focus: 0,
              gap: 20,
              perPage: 3,
              perMove: 1,
              type: 'slide',
              arrows: true,
              pagination: false,
              breakpoints: {
                991: {
                  perPage: 3,
                  gap: 7,
                },
                767: {
                  perPage: 2,
                },
                479: {
                  perPage: 1,
                },
              },
            }"
          >
            <SplideSlide v-for="(item, ind) in currentData" :key="ind">
              <div v-if="item.actionType == 'payment'" class="storybottomslide">
                <img
                  :src="item.image"
                  loading="lazy"
                  alt=""
                  class="bottomimage"
                />

                <div class="bottomcontentwrapper sub-project">
                  <h1 class="bottomtitle">
                    {{ item.title }}
                  </h1>
                  <p class="bottomparagraph" v-if="item.details">
                    {{ item.details }}
                  </p>

                  <div class="quantityDiv">
                    <h4 class="detail-text mb-0">£{{ item.amount }}</h4>
                    <h4 class="detail-text mb-0">x</h4>
                    <lv-number
                      placeholder="Enter Number"
                      v-model="item.value"
                      :min="0"
                      :max="999"
                      buttonColor="set-lvButton"
                    />
                  </div>
                  <a
                    data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                    class="button-primary w-button"
                    @click="openDialog(item)"
                    >{{ item.buttonTitle
                    }}<span class="button-amount"
                      >({{ checkValue(item) }})</span
                    ></a
                  >
                </div>
              </div>

              <div v-else class="storybottomslide">
                <a
                  :href="getRoute(item, 'p')"
                  :target="item.actionType == 'url' ? '_blank' : '_self'"
                >
                  <!-- <div class="bottomimage image-overlay"></div> -->
                  <img
                    :src="item.image"
                    loading="lazy"
                    alt=""
                    class="bottomimage"
                  />

                  <div class="bottomcontentwrapper">
                    <h1 class="bottomtitle">
                      {{ item.title }}
                    </h1>
                    <p v-if="item.details" class="bottomparagraph">
                      {{ item.details }}
                    </p>
                    <a
                      v-if="item.actionType != 'none'"
                      data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                      class="button-primary w-button"
                    >
                      {{ item.buttonTitle }}
                    </a>
                  </div>
                </a>
              </div>
            </SplideSlide>
          </Splide>
        </div>

        <div class="container-2" v-else>
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{
              data.content.projects.title
            }}</span>
          </h1>
          <Splide
            class="storybottomslider"
            :options="{
              // rewind: false,
              omitEnd: true,
              focus: 0,
              gap: 7,
              perPage: 4,
              perMove: 1,
              type: 'slide',
              arrows: true,
              pagination: false,
              breakpoints: {
                991: {
                  perPage: 3,
                },
                767: {
                  perPage: 2,
                },
                479: {
                  perPage: 1,
                },
              },
            }"
          >
            <SplideSlide
              v-for="(item, ind) in data.content.projectDetails"
              :key="ind"
            >
              <div v-if="item.actionType == 'payment'" class="storybottomslide">
                <img
                  :src="item.image"
                  loading="lazy"
                  alt=""
                  class="bottomimage"
                />

                <div class="bottomcontentwrapper sub-project">
                  <h1 class="bottomtitle">
                    {{ item.title }}
                  </h1>
                  <p class="bottomparagraph" v-if="item.details">
                    {{ item.details }}
                  </p>

                  <div class="quantityDiv">
                    <h4 class="detail-text mb-0">£{{ item.amount }}</h4>
                    <h4 class="detail-text mb-0">x</h4>
                    <lv-number
                      placeholder="Enter Number"
                      v-model="item.value"
                      :min="0"
                      :max="999"
                      buttonColor="set-lvButton"
                    />
                  </div>
                  <a
                    data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                    class="button-primary w-button"
                    @click="openDialog(item)"
                    >{{ item.buttonTitle
                    }}<span class="button-amount"
                      >({{ checkValue(item) }})</span
                    ></a
                  >
                </div>
              </div>

              <div v-else class="storybottomslide" @click="checkSub(item)">
                <a
                  :href="getRoute(item, 'p')"
                  :target="item.actionType == 'url' ? '_blank' : '_self'"
                >
                  <!-- <div class="bottomimage image-overlay"></div> -->
                  <img
                    :src="item.image"
                    loading="lazy"
                    alt=""
                    class="bottomimage"
                  />

                  <div class="bottomcontentwrapper">
                    <h1 class="bottomtitle">
                      {{ item.title }}
                    </h1>
                    <p v-if="item.details" class="bottomparagraph">
                      {{ item.details }}
                    </p>
                    <a
                      v-if="item.actionType != 'none'"
                      data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                      class="button-primary w-button"
                    >
                      {{ item.buttonTitle }}
                    </a>
                  </div>
                </a>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>

      <section v-else-if="data.type == 'Video'" class="storysection wf-section">
        <div class="container-2">
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{ data.content.video.title }}</span>
          </h1>
          <figure class="video-wrapper">
            <iframe
              width="560"
              height="315"
              :src="data.content.video.link.replace('watch?v=', 'embed/')"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </figure>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Take action'"
        class="donatesection wf-section"
      >
        <div class="donate-container">
          <div class="takeDiv">
            <h1 class="take-heading">{{ data.content.action.title }}</h1>
            <h1
              class="take-heading green-heading"
              v-if="data.content.action.title2"
            >
              {{ data.content.action.title2 }}
            </h1>
            <h4
              class="volunteerdetails"
              v-html="data.content.action.details.replace(/\n/g, '<br />')"
            ></h4>
          </div>
          <div
            class="takeDiv buttons-wrapper"
            v-if="data.content.action.buttonData.length > 0"
          >
            <template v-for="item in data.content.action.buttonData">
              <a
                :href="getRoute(item, '')"
                :target="item.actionType == 'url' ? '_blank' : '_self'"
                class="button-primary w-button takeButton"
                >{{ item.title }}</a
              >
              <br />
            </template>
            <!-- @click="toggleDonate('open')" -->
          </div>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Fund Raiser'"
        class="storysection wf-section"
      >
        <div class="container-2">
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{ data.content.funds.title }}</span>
          </h1>
          <Splide
            class="storybottomslider"
            :options="{
              omitEnd: true,
              focus: 0,
              gap: 20,
              perPage: 3,
              perMove: 1,
              type: 'slide',
              arrows: true,
              pagination: false,
              breakpoints: {
                991: {
                  perPage: 3,
                  gap: 7,
                },
                767: {
                  perPage: 2,
                },
                479: {
                  perPage: 1,
                },
              },
            }"
          >
            <SplideSlide
              v-for="(item, ind) in data.content.fundDetails"
              :key="ind"
            >
              <div class="storybottomslide">
                <a :href="getRoute(item, 'c')">
                  <!-- <div class="bottomimage image-overlay"></div> -->
                  <img
                    :src="item.image"
                    loading="lazy"
                    alt="fund"
                    class="bottomimage"
                  />

                  <div class="bottomcontentwrapper">
                    <h5 class="fundtitle">
                      {{ item.title }}
                    </h5>
                    <div class="fund-stats">
                      <div>
                        <img
                          src="@/assets/images/worldappeal/icons8-share-50.png"
                          alt=""
                        />
                        <span>{{ item.donationCount }}</span>
                      </div>
                      <div>
                        <img
                          src="@/assets/images/worldappeal/icons8-clock-50.png"
                          alt=""
                        />
                        <span>{{ getDays(item.validTill) }} days left</span>
                      </div>
                    </div>
                    <progress
                      :value="getProgress(item)"
                      max="100"
                      class="fund-progress"
                    ></progress>
                    <div class="fund-amount-div">
                      <div>
                        <h4>£{{ item.collectedAmount }}</h4>
                        <p>Funded of £{{ item.fundAmount }}</p>
                      </div>
                      <a
                        data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                        class="button-primary w-button"
                        >{{ item.buttonTitle }}</a
                      >
                    </div>
                  </div>
                </a>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>

      <section v-else-if="data.type == 'Blogs'" class="storysection wf-section">
        <div class="container-2">
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{ data.content.blogs.title }}</span>
          </h1>
          <Splide
            class="storybottomslider"
            :options="{
              omitEnd: true,
              focus: 0,
              gap: 20,
              perPage: 3,
              perMove: 1,
              type: 'slide',
              arrows: true,
              pagination: false,
              breakpoints: {
                991: {
                  perPage: 3,
                  gap: 7,
                },
                767: {
                  perPage: 2,
                },
                479: {
                  perPage: 1,
                },
              },
            }"
          >
            <SplideSlide
              v-for="(item, ind) in data.content.blogDetails"
              :key="ind"
            >
              <div class="storybottomslide">
                <a :href="'/blog?id=' + item.id" target="_self">
                  <img
                    :src="item.image"
                    loading="lazy"
                    alt=""
                    class="bottomimage"
                  />

                  <div class="bottomcontentwrapper">
                    <h1 class="bottomtitle">
                      {{ item.title }}
                    </h1>
                    <p v-if="item.description" class="bottomparagraph blogdesc">
                      {{ item.description }}
                    </p>
                    <a
                      data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                      class="button-primary w-button"
                      >Read More</a
                    >
                  </div>
                </a>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Split Section'"
        class="storysection wf-section"
      >
        <div class="container-2">
          <template v-if="!data.content.splitData.titleRight">
            <h1 class="centered-heading">
              <span class="highlightheading">{{
                data.content.splitData.title
              }}</span>
            </h1>
            <div class="underlined-div"></div>
          </template>

          <div class="about-wrapper">
            <div class="about-images-grid">
              <div>
                <video v-if="isVideo(data.content.splitData.image)" controls>
                  <source
                    :src="data.content.splitData.image"
                    type="video/mp4"
                  />
                </video>
                <img v-else :src="data.content.splitData.image" alt="" />
              </div>
            </div>

            <div class="">
              <template v-if="data.content.splitData.titleRight">
                <h1 class="centered-heading">
                  <span class="highlightheading">{{
                    data.content.splitData.title
                  }}</span>
                </h1>
                <div class="underlined-div"></div>
              </template>
              <div
                class="about-content-para"
                v-html="data.content.splitData.details.replace(/\n/g, '<br />')"
              ></div>
            </div>
          </div>
        </div>
      </section>

      <template v-else-if="data.type == 'Info'">
        <section
          v-if="data.content.infoData.isColored"
          class="storysection gradient-section wf-section"
        >
          <div class="container-2">
            <img
              v-if="data.content.infoData.watermark"
              :src="data.content.infoData.watermark"
              alt=""
              class="bg-image"
            />
            <h1 class="centered-heading purple left-bordered">
              <span class="highlightheading-white">{{
                data.content.infoData.title
              }}</span>
            </h1>
            <div class="about-content">
              <div
                class="about-content-para para-white"
                v-html="data.content.infoData.details.replace(/\n/g, '<br />')"
              ></div>
            </div>
          </div>
        </section>

        <section v-else class="storysection wf-section">
          <div class="container-2">
            <h1 class="centered-heading left-bordered">
              <span class="highlightheading">{{
                data.content.infoData.title
              }}</span>
            </h1>
            <div class="about-content">
              <div
                class="about-content-para"
                v-html="data.content.infoData.details.replace(/\n/g, '<br />')"
              ></div>
            </div>
          </div>
        </section>
      </template>

      <section
        v-else-if="data.type == 'Contact Form'"
        class="storysection gradient-section wf-section"
      >
        <div class="container-2">
          <h1 class="centered-heading purple left-bordered">
            <span class="highlightheading-white">{{
              data.content.contact.title
            }}</span>
          </h1>
          <div class="contact-form-wrapper">
            <form
              id="contact-form"
              name="contact-form"
              data-name="contact-form"
              method="get"
              class="contact-form"
              @submit.prevent="contactSubmit(data.content.contact.type)"
            >
              <div class="contact-form-div">
                <div class="contact-form-input-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    class=""
                    placeholder=""
                    required=""
                    v-model="mailObj.name"
                  />
                </div>
                <div class="contact-form-input-group">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    class=""
                    placeholder=""
                    required=""
                    v-model="mailObj.email"
                  />
                </div>
                <div class="contact-form-input-group">
                  <label for="phone">Phone</label>
                  <input
                    id="phone"
                    type="tel"
                    class=""
                    placeholder=""
                    required=""
                    v-model="mailObj.phone"
                  />
                </div>
              </div>
              <div class="contact-form-div">
                <div
                  class="contact-form-input-group"
                  v-if="data.content.contact.type == 'fundraising'"
                >
                  <label for="address">Address</label>
                  <input
                    id="address"
                    type="text"
                    class=""
                    placeholder=""
                    required=""
                    v-model="mailObj.address"
                  />
                </div>
                <div
                  class="contact-form-input-group"
                  v-else-if="data.content.contact.type == 'volunteer'"
                >
                  <label for="job">Job</label>
                  <input
                    id="job"
                    type="text"
                    class=""
                    placeholder=""
                    required=""
                    v-model="mailObj.job"
                  />
                </div>
                <div class="contact-form-input-group" v-else>
                  <label for="subject">Subject</label>
                  <input
                    id="subject"
                    type="text"
                    class=""
                    placeholder=""
                    required=""
                    v-model="mailObj.subject"
                  />
                </div>
                <div class="contact-form-input-group">
                  <label for="msg">{{
                    data.content.contact.type == "fundraising"
                      ? "Fundraising Plans"
                      : data.content.contact.type == "volunteer"
                      ? "Experience"
                      : "Message"
                  }}</label>
                  <textarea
                    id="msg"
                    class=""
                    placeholder=""
                    rows="6"
                    required=""
                    v-model="mailObj.message"
                  ></textarea>
                </div>
              </div>

              <div class="form-button-block">
                <button
                  type="submit"
                  class="button-primary w-button"
                  :disabled="request"
                >
                  <div
                    class="custom-mini-loader"
                    v-if="request"
                    style="margin: auto"
                  ></div>
                  <span v-else>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Donation Banner'"
        class="storysection wf-section"
      >
        <div class="container-2">
          <div class="row">
            <div
              class="col-lg-8 quick-donation-bg"
              :style="{
                'background-image':
                  'url(' + data.content.donationBanner.image + ')',
              }"
            ></div>
            <div class="col-lg-4 quick-donation">
              <form
                class="d-flex flex-column h-100"
                @submit.prevent="donateSubmit(data.content.donationBanner)"
              >
                <h1 class="quick-heading">
                  {{ data.content.donationBanner.title }}
                </h1>

                <div class="mb-3">
                  <label for="type"
                    >How often would you like to give?
                    <span class="text-danger">*</span></label
                  >
                  <select
                    class="custom-select form-control d-block w-100"
                    v-model="paymentObj.recurring"
                    id="type"
                    required=""
                  >
                    <option value="Single Donation">One Time</option>
                    <option value="Recurring Donation">Monthly</option>
                  </select>
                  <div class="invalid-feedback">
                    Please select a valid type.
                  </div>
                </div>

                <div class="input-group quick-input">
                  <div class="input-group-prepend">
                    <span class="input-group-text">£</span>
                  </div>
                  <input
                    :ref="'amount' + index"
                    type="number"
                    v-model="data.content.donationBanner.amount"
                    required
                    class="form-control"
                    @focus="data.content.donationBanner.selected = 'Custom'"
                  />
                </div>
                <div class="quick-button-wrapper">
                  <div
                    v-for="(item, ind) in getQuickData(
                      data.content.donationBanner.initialAmount
                    )"
                    :key="item"
                    class="quick-button"
                    :class="
                      data.content.donationBanner.selected == item && 'selected'
                    "
                    @click="setAmount(data.content.donationBanner, item, index)"
                  >
                    <sup v-if="ind != 5">£</sup>{{ item }}
                  </div>
                </div>

                <div class="d-flex justify-content-center mt-auto">
                  <button
                    class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Donation Card'"
        class="storysection wf-section"
      >
        <div class="container-2">
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{
              data.content.donationCard.title
            }}</span>
          </h1>

          <div class="mt-4 donation-card-wrapper">
            <div
              class="text-center donation-card-heading"
              v-if="data.content.donationCard.showInfo"
            >
              <button
                class="back-btn"
                @click="data.content.donationCard.showInfo = false"
                :disabled="otherLoading"
              >
                <i class="light-icon-chevron-left"></i>
              </button>
              Add Your Information
            </div>
            <div class="text-center donation-card-heading" v-else>
              Choose Amount
            </div>
            <div class="donation-card-details">
              <div
                class="donation-card-quick"
                :class="data.content.donationCard.showInfo && 'd-none'"
              >
                <form
                  class="d-flex flex-column h-100"
                  @submit.prevent="Next(data.content.donationCard, index)"
                >
                  <h5>Choose, enter or select a donation method</h5>

                  <div class="mb-3">
                    <label for="type"
                      >How often would you like to give?
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="custom-select form-control d-block w-100"
                      v-model="otherObj.recurring"
                      id="type"
                      required=""
                    >
                      <option value="Single Donation">One Time</option>
                      <option value="Recurring Donation">Monthly</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select a valid type.
                    </div>
                  </div>

                  <div class="input-group quick-input">
                    <div class="input-group-prepend">
                      <span class="input-group-text">£</span>
                    </div>
                    <input
                      :ref="'amount' + index"
                      type="number"
                      v-model="data.content.donationCard.amount"
                      required
                      class="form-control"
                      @focus="data.content.donationCard.selected = 'Custom'"
                    />
                  </div>
                  <div class="quick-button-wrapper">
                    <div
                      v-for="(item, ind) in getQuickData(
                        data.content.donationCard.initialAmount
                      )"
                      :key="item"
                      class="quick-button"
                      :class="
                        data.content.donationCard.selected == item && 'selected'
                      "
                      @click="setAmount(data.content.donationCard, item, index)"
                    >
                      <sup v-if="ind != 5">£</sup>{{ item }}
                    </div>
                  </div>

                  <div class="d-flex justify-content-center mt-auto">
                    <button
                      class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                      type="submit"
                    >
                      Continue <i class="light-icon-chevron-right"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div
                class="donation-card-info"
                :class="data.content.donationCard.showInfo && 'add-animation'"
              >
                <form
                  @submit.prevent="
                    otherSubmit(data.content.donationCard, index)
                  "
                >
                  <div class="">
                    <h5>We’ll never share this information with anyone.</h5>
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="firstName"
                          >First name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="firstName"
                          placeholder=""
                          v-model="otherObj.firstName"
                          required=""
                        />
                        <div class="invalid-feedback">
                          Valid first name is required.
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="lastName"
                          >Last name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="lastName"
                          placeholder=""
                          v-model="otherObj.lastName"
                          required=""
                        />
                        <div class="invalid-feedback">
                          Valid last name is required.
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="email"
                          >Email <span class="text-danger">*</span></label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder=""
                          required=""
                          v-model="otherObj.email"
                        />
                        <div class="invalid-feedback">
                          Please enter a valid email address
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <label for="contact"
                          >Contact <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="contact"
                          placeholder=""
                          required=""
                          v-model="otherObj.contact"
                        />
                        <div class="invalid-feedback">
                          Please enter a valid contact
                        </div>
                      </div>
                    </div>

                    <div class="mb-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="otherObj.anonymous"
                        id="anonymous"
                      />
                      <label class="form-check-label mx-1" for="anonymous">
                        Make this an anonymous donation
                      </label>
                    </div>

                    <div class="mb-3">
                      <label for="type"
                        >Donation Type <span class="text-danger">*</span></label
                      >
                      <select
                        class="custom-select form-control d-block w-100"
                        v-model="otherObj.donationType"
                        id="type"
                        required=""
                      >
                        <option v-for="dt in typeOptions" :key="dt" :value="dt">
                          {{ dt }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid type.
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="country"
                        >Country <span class="text-danger">*</span></label
                      >
                      <select
                        id="country"
                        class="form-control"
                        required=""
                        v-model="otherObj.country"
                        @change="countryChange"
                      >
                        <option
                          v-for="dt in countries"
                          :key="dt.name"
                          :value="dt.value"
                        >
                          {{ dt.name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="row"
                      v-if="otherObj.country == 'United Kingdom'"
                    >
                      <div class="col-md-10 mb-2">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="e.g. Manor Farm Barns, Framingham Pigot or NR14 7PZ"
                          v-model.trim="searchQuery"
                        />
                      </div>
                      <div class="col-md-2 mb-2">
                        <button
                          class="btn btn-warning"
                          :disabled="searching"
                          @click="searchAddress"
                        >
                          Search
                        </button>
                      </div>
                      <div v-if="searching" class="mb-2">
                        Searching addresses...
                      </div>
                      <div
                        class="col-md-12 mb-3"
                        v-if="suggestionList.length > 0"
                      >
                        <select
                          class="form-control"
                          v-model="suggestedAddress"
                          @change="setAddress"
                        >
                          <option value="" hidden>Select an address</option>
                          <option
                            v-for="dt in suggestionList"
                            :key="dt.summaryline"
                            :value="dt"
                          >
                            {{ dt.summaryline }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="address"
                        >Address <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="address"
                        placeholder="Address Line 1 *"
                        required=""
                        v-model="otherObj.address.addressline1"
                        :disabled="otherObj.country == 'United Kingdom'"
                      />
                    </div>
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address Line 2 *"
                        required=""
                        v-model="otherObj.address.addressline2"
                        :disabled="otherObj.country == 'United Kingdom'"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="City *"
                          required=""
                          v-model="otherObj.address.posttown"
                          :disabled="otherObj.country == 'United Kingdom'"
                        />
                      </div>
                      <div class="col-md-4 mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="State / Province / Region *"
                          required=""
                          v-model="otherObj.address.county"
                          :disabled="otherObj.country == 'United Kingdom'"
                        />
                      </div>
                      <div class="col-md-4 mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Postal / Zipcode *"
                          required=""
                          v-model="otherObj.address.postcode"
                          :disabled="otherObj.country == 'United Kingdom'"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 mb-2">
                        I would like to be kept up to date with feedback about
                        my donations and news updates by:
                      </div>
                      <div class="col-md-3 mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="otherObj.whatsapp"
                          id="whatsapp"
                        />
                        <label class="form-check-label mx-1" for="whatsapp">
                          Whatsapp
                        </label>
                      </div>
                      <div class="col-md-3 mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="otherObj.post"
                          id="post"
                        />
                        <label class="form-check-label mx-1" for="post">
                          Post
                        </label>
                      </div>
                      <div class="col-md-3 mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="otherObj.emailCheck"
                          id="emailCheck"
                        />
                        <label class="form-check-label mx-1" for="emailCheck">
                          Email
                        </label>
                      </div>
                      <div class="col-md-3 mb-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="otherObj.sms"
                          id="sms"
                        />
                        <label class="form-check-label mx-1" for="sms">
                          SMS
                        </label>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="cc-name"
                        >Name on card <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="cc-name"
                        placeholder=""
                        v-model="otherObj.cardName"
                        required=""
                      />

                      <div class="invalid-feedback">
                        Name on card is required
                      </div>
                    </div>

                    <div :id="'card-element' + index"></div>

                    <template v-if="otherObj.country == 'United Kingdom'">
                      <div class="mb-2 mt-2">
                        <label>
                          <img
                            src="@/assets/images/worldappeal/gift-aid-logo.png"
                            alt=""
                            class="gift-aid-image"
                          />
                          <b
                            >Add 25% more to your donation at no cost to you.</b
                          >
                          A Gift Aid declaration allows to claim tax back on
                          eligible donations. It means that for every £1 you
                          donate to we can claim back 25p, at no extra cost to
                          you.
                        </label>
                      </div>
                      <div class="mb-1">
                        <PieChart
                          :value="getCardChartValue(data.content.donationCard)"
                        />
                      </div>
                      <div class="mb-1">
                        <input
                          class="form-check-input gift-aid-checkbox"
                          type="checkbox"
                          v-model="otherObj.giftAid"
                          id="gift-aid"
                        />
                        <label
                          class="form-check-label gift-aid-label"
                          for="gift-aid"
                        >
                          Yes, I would like to claim Gift Aid
                        </label>
                      </div>
                      <small class="text-muted">
                        By ticking the "Yes" box, I agree I would like to
                        reclaim the tax on all qualifying donations I have made,
                        as well as any future donations, until I notify them
                        otherwise. I understand that if I pay less Income Tax
                        and/or Capital Gains Tax than the amount of Gift Aid
                        claimed on all my donations in that tax year I may be
                        asked to pay any difference. I understand that will
                        reclaim 25p of tax on every £1 that I give.
                      </small>
                    </template>
                  </div>
                  <div
                    :id="'card-error' + index"
                    class="text-danger mt-3"
                  ></div>

                  <div class="d-flex justify-content-center mt-3">
                    <button
                      class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                      type="submit"
                      :disabled="otherLoading"
                    >
                      Donate Now (£{{
                        getCardAmount(data.content.donationCard)
                      }})
                      <div
                        class="custom-mini-loader"
                        v-if="otherLoading"
                        style="margin-left: 10px"
                      ></div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Partners'"
        class="storysection wf-section"
      >
        <div class="container-2">
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{
              data.content.partnerData.title
            }}</span>
          </h1>

          <div class="partner-wrapper">
            <div v-for="item in data.content.partners" class="partner-item">
              <img :src="item.image" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Priorities'"
        class="storysection wf-section"
      >
        <div class="container-2">
          <h1 class="centered-heading">
            <span class="highlightheading">{{
              data.content.priorData.title
            }}</span>
          </h1>
          <div class="underlined-div"></div>
          <div class="action-wrapper">
            <a
              class="action-div"
              v-for="item in data.content.priorDetails"
              :href="'/page/' + item.pageID"
            >
              <div class="action-item" :key="item.title">
                <img :src="item.icon" alt="" />
              </div>
              <h2 class="action-title">{{ item.title }}</h2>
            </a>
          </div>
        </div>
      </section>

      <section v-else-if="data.type == 'Faqs'" class="storysection wf-section">
        <div class="container-2">
          <h1 class="centered-heading left-bordered">
            <span class="highlightheading">{{
              data.content.faqData.title
            }}</span>
          </h1>

          <div class="accordian-wrapper">
            <div
              class="faq-item"
              v-for="(item, ind) in data.content.faqData.faqs"
              :key="ind"
            >
              <div
                class="faq-title"
                @click="openFaq(data.content.faqData.faqs, ind)"
              >
                <div class="faq-text">{{ item.text }}</div>
                <i
                  :class="
                    item.state
                      ? 'light-icon-chevron-up'
                      : 'light-icon-chevron-down'
                  "
                  class="faq-icon"
                ></i>
              </div>
              <LvCollapsible :show="item.state">
                <div class="faq-answer" v-html="item.answer"></div>
              </LvCollapsible>
            </div>
          </div>
        </div>
      </section>

      <!-- class="slider" -->
      <section
        v-else-if="data.type == 'Carousel'"
        class="storysection wf-section"
        :class="index == 0 && 'pt-0'"
      >
        <div class="container-2">
          <Splide
            :options="{
              drag: true,
              gap: 0,
              perPage: 1,
              perMove: 1,
              type: 'slide',
              arrows: false,
              pagination: data.content.carouselData.pagination,
              autoplay: data.content.carouselData.autoplay,
              rewind: true,
              interval: 6000,
              pauseOnHover: false,
              easing: 'linear',
            }"
          >
            <SplideSlide
              v-for="(item, ind) in data.content.carouselData.images"
            >
              <img :src="item" alt="" class="carousel-image" />
            </SplideSlide>
          </Splide>
        </div>
      </section>

      <section
        v-else-if="data.type == 'Colored Box'"
        class="storysection wf-section pt-0"
      >
        <div class="container-2">
          <div
            class="alert alert-success"
            role="alert"
            v-html="data.content.boxData.details.replace(/\n/g, '<br />')"
          ></div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import axios from "axios";
import LvSkeleton from "lightvue/skeleton";
import LvNumber from "lightvue/number";
import LvDialog from "lightvue/dialog";
import LvButton from "lightvue/button";
import LvCollapsible from "lightvue/collapsible";

import { useToast } from "vue-toastification";

import PieChart from "@/components/PieChart.vue";

export default {
  name: "HomeNew",
  components: {
    HelloWorld,
    Splide,
    SplideSlide,
    LvSkeleton,
    LvNumber,
    LvDialog,
    LvButton,
    LvCollapsible,
    PieChart,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      animalSelected: "",
      countries: [
        {
          name: "Afghanistan",
          value: "Afghanistan",
          code: "AF",
        },
        {
          name: "Åland Islands",
          value: "Åland Islands",
          code: "AX",
        },
        {
          name: "Albania",
          value: "Albania",
          code: "AL",
        },
        {
          name: "Algeria",
          value: "Algeria",
          code: "DZ",
        },
        {
          name: "Andorra",
          value: "Andorra",
          code: "AD",
        },
        {
          name: "Angola",
          value: "Angola",
          code: "AO",
        },
        {
          name: "Anguilla",
          value: "Anguilla",
          code: "AI",
        },
        {
          name: "Antarctica",
          value: "Antarctica",
          code: "AQ",
        },
        {
          name: "Antigua and Barbuda",
          value: "Antigua and Barbuda",
          code: "AG",
        },
        {
          name: "Argentina",
          value: "Argentina",
          code: "AR",
        },
        {
          name: "Armenia",
          value: "Armenia",
          code: "AM",
        },
        {
          name: "Aruba",
          value: "Aruba",
          code: "AW",
        },
        {
          name: "Ascension Island",
          value: "Ascension Island",
          code: "AC",
        },
        {
          name: "Australia",
          value: "Australia",
          code: "AU",
        },
        {
          name: "Austria",
          value: "Austria",
          code: "AT",
        },
        {
          name: "Azerbaijan",
          value: "Azerbaijan",
          code: "AZ",
        },
        {
          name: "Bahamas",
          value: "Bahamas",
          code: "BS",
        },
        {
          name: "Bahrain",
          value: "Bahrain",
          code: "BH",
        },
        {
          name: "Bangladesh",
          value: "Bangladesh",
          code: "BD",
        },
        {
          name: "Barbados",
          value: "Barbados",
          code: "BB",
        },
        {
          name: "Belarus",
          value: "Belarus",
          code: "BY",
        },
        {
          name: "Belgium",
          value: "Belgium",
          code: "BE",
        },
        {
          name: "Belize",
          value: "Belize",
          code: "BZ",
        },
        {
          name: "Benin",
          value: "Benin",
          code: "BJ",
        },
        {
          name: "Bermuda",
          value: "Bermuda",
          code: "BM",
        },
        {
          name: "Bhutan",
          value: "Bhutan",
          code: "BT",
        },
        {
          name: "Bolivia",
          value: "Bolivia",
          code: "BO",
        },
        {
          name: "Bosnia and Herzegovina",
          value: "Bosnia and Herzegovina",
          code: "BA",
        },
        {
          name: "Botswana",
          value: "Botswana",
          code: "BW",
        },
        {
          name: "Bouvet Island",
          value: "Bouvet Island",
          code: "BV",
        },
        {
          name: "Brazil",
          value: "Brazil",
          code: "BR",
        },
        {
          name: "British Indian Ocean Territory",
          value: "British Indian Ocean Territory",
          code: "IO",
        },
        {
          name: "British Virgin Islands",
          value: "British Virgin Islands",
          code: "VG",
        },
        {
          name: "Brunei",
          value: "Brunei",
          code: "BN",
        },
        {
          name: "Bulgaria",
          value: "Bulgaria",
          code: "BG",
        },
        {
          name: "Burkina Faso",
          value: "Burkina Faso",
          code: "BF",
        },
        {
          name: "Burundi",
          value: "Burundi",
          code: "BI",
        },
        {
          name: "Cambodia",
          value: "Cambodia",
          code: "KH",
        },
        {
          name: "Cameroon",
          value: "Cameroon",
          code: "CM",
        },
        {
          name: "Canada",
          value: "Canada",
          code: "CA",
        },
        {
          name: "Cape Verde",
          value: "Cape Verde",
          code: "CV",
        },
        {
          name: "Caribbean Netherlands",
          value: "Caribbean Netherlands",
          code: "BQ",
        },
        {
          name: "Cayman Islands",
          value: "Cayman Islands",
          code: "KY",
        },
        {
          name: "Central African Republic",
          value: "Central African Republic",
          code: "CF",
        },
        {
          name: "Chad",
          value: "Chad",
          code: "TD",
        },
        {
          name: "Chile",
          value: "Chile",
          code: "CL",
        },
        {
          name: "China",
          value: "China",
          code: "CN",
        },
        {
          name: "Colombia",
          value: "Colombia",
          code: "CO",
        },
        {
          name: "Comoros",
          value: "Comoros",
          code: "KM",
        },
        {
          name: "Congo - Brazzaville",
          value: "Congo - Brazzaville",
          code: "CG",
        },
        {
          name: "Congo - Kinshasa",
          value: "Congo - Kinshasa",
          code: "CD",
        },
        {
          name: "Cook Islands",
          value: "Cook Islands",
          code: "CK",
        },
        {
          name: "Costa Rica",
          value: "Costa Rica",
          code: "CR",
        },
        {
          name: "Côte d’Ivoire",
          value: "Côte d’Ivoire",
          code: "CI",
        },
        {
          name: "Croatia",
          value: "Croatia",
          code: "HR",
        },
        {
          name: "Curaçao",
          value: "Curaçao",
          code: "CW",
        },
        {
          name: "Cyprus",
          value: "Cyprus",
          code: "CY",
        },
        {
          name: "Czechia",
          value: "Czechia",
          code: "CZ",
        },
        {
          name: "Denmark",
          value: "Denmark",
          code: "DK",
        },
        {
          name: "Djibouti",
          value: "Djibouti",
          code: "DJ",
        },
        {
          name: "Dominica",
          value: "Dominica",
          code: "DM",
        },
        {
          name: "Dominican Republic",
          value: "Dominican Republic",
          code: "DO",
        },
        {
          name: "Ecuador",
          value: "Ecuador",
          code: "EC",
        },
        {
          name: "Egypt",
          value: "Egypt",
          code: "EG",
        },
        {
          name: "El Salvador",
          value: "El Salvador",
          code: "SV",
        },
        {
          name: "Equatorial Guinea",
          value: "Equatorial Guinea",
          code: "GQ",
        },
        {
          name: "Eritrea",
          value: "Eritrea",
          code: "ER",
        },
        {
          name: "Estonia",
          value: "Estonia",
          code: "EE",
        },
        {
          name: "Eswatini",
          value: "Eswatini",
          code: "SZ",
        },
        {
          name: "Ethiopia",
          value: "Ethiopia",
          code: "ET",
        },
        {
          name: "Falkland Islands",
          value: "Falkland Islands",
          code: "FK",
        },
        {
          name: "Faroe Islands",
          value: "Faroe Islands",
          code: "FO",
        },
        {
          name: "Fiji",
          value: "Fiji",
          code: "FJ",
        },
        {
          name: "Finland",
          value: "Finland",
          code: "FI",
        },
        {
          name: "France",
          value: "France",
          code: "FR",
        },
        {
          name: "French Guiana",
          value: "French Guiana",
          code: "GF",
        },
        {
          name: "French Polynesia",
          value: "French Polynesia",
          code: "PF",
        },
        {
          name: "French Southern Territories",
          value: "French Southern Territories",
          code: "TF",
        },
        {
          name: "Gabon",
          value: "Gabon",
          code: "GA",
        },
        {
          name: "Gambia",
          value: "Gambia",
          code: "GM",
        },
        {
          name: "Georgia",
          value: "Georgia",
          code: "GE",
        },
        {
          name: "Germany",
          value: "Germany",
          code: "DE",
        },
        {
          name: "Ghana",
          value: "Ghana",
          code: "GH",
        },
        {
          name: "Gibraltar",
          value: "Gibraltar",
          code: "GI",
        },
        {
          name: "Greece",
          value: "Greece",
          code: "GR",
        },
        {
          name: "Greenland",
          value: "Greenland",
          code: "GL",
        },
        {
          name: "Grenada",
          value: "Grenada",
          code: "GD",
        },
        {
          name: "Guadeloupe",
          value: "Guadeloupe",
          code: "GP",
        },
        {
          name: "Guam",
          value: "Guam",
          code: "GU",
        },
        {
          name: "Guatemala",
          value: "Guatemala",
          code: "GT",
        },
        {
          name: "Guernsey",
          value: "Guernsey",
          code: "GG",
        },
        {
          name: "Guinea",
          value: "Guinea",
          code: "GN",
        },
        {
          name: "Guinea-Bissau",
          value: "Guinea-Bissau",
          code: "GW",
        },
        {
          name: "Guyana",
          value: "Guyana",
          code: "GY",
        },
        {
          name: "Haiti",
          value: "Haiti",
          code: "HT",
        },
        {
          name: "Honduras",
          value: "Honduras",
          code: "HN",
        },
        {
          name: "Hong Kong SAR China",
          value: "Hong Kong SAR China",
          code: "HK",
        },
        {
          name: "Hungary",
          value: "Hungary",
          code: "HU",
        },
        {
          name: "Iceland",
          value: "Iceland",
          code: "IS",
        },
        {
          name: "India",
          value: "India",
          code: "IN",
        },
        {
          name: "Indonesia",
          value: "Indonesia",
          code: "ID",
        },
        {
          name: "Iraq",
          value: "Iraq",
          code: "IQ",
        },
        {
          name: "Ireland",
          value: "Ireland",
          code: "IE",
        },
        {
          name: "Isle of Man",
          value: "Isle of Man",
          code: "IM",
        },
        {
          name: "Israel",
          value: "Israel",
          code: "IL",
        },
        {
          name: "Italy",
          value: "Italy",
          code: "IT",
        },
        {
          name: "Jamaica",
          value: "Jamaica",
          code: "JM",
        },
        {
          name: "Japan",
          value: "Japan",
          code: "JP",
        },
        {
          name: "Jersey",
          value: "Jersey",
          code: "JE",
        },
        {
          name: "Jordan",
          value: "Jordan",
          code: "JO",
        },
        {
          name: "Kazakhstan",
          value: "Kazakhstan",
          code: "KZ",
        },
        {
          name: "Kenya",
          value: "Kenya",
          code: "KE",
        },
        {
          name: "Kiribati",
          value: "Kiribati",
          code: "KI",
        },
        {
          name: "Kosovo",
          value: "Kosovo",
          code: "XK",
        },
        {
          name: "Kuwait",
          value: "Kuwait",
          code: "KW",
        },
        {
          name: "Kyrgyzstan",
          value: "Kyrgyzstan",
          code: "KG",
        },
        {
          name: "Laos",
          value: "Laos",
          code: "LA",
        },
        {
          name: "Latvia",
          value: "Latvia",
          code: "LV",
        },
        {
          name: "Lebanon",
          value: "Lebanon",
          code: "LB",
        },
        {
          name: "Lesotho",
          value: "Lesotho",
          code: "LS",
        },
        {
          name: "Liberia",
          value: "Liberia",
          code: "LR",
        },
        {
          name: "Libya",
          value: "Libya",
          code: "LY",
        },
        {
          name: "Liechtenstein",
          value: "Liechtenstein",
          code: "LI",
        },
        {
          name: "Lithuania",
          value: "Lithuania",
          code: "LT",
        },
        {
          name: "Luxembourg",
          value: "Luxembourg",
          code: "LU",
        },
        {
          name: "Macao SAR China",
          value: "Macao SAR China",
          code: "MO",
        },
        {
          name: "Madagascar",
          value: "Madagascar",
          code: "MG",
        },
        {
          name: "Malawi",
          value: "Malawi",
          code: "MW",
        },
        {
          name: "Malaysia",
          value: "Malaysia",
          code: "MY",
        },
        {
          name: "Maldives",
          value: "Maldives",
          code: "MV",
        },
        {
          name: "Mali",
          value: "Mali",
          code: "ML",
        },
        {
          name: "Malta",
          value: "Malta",
          code: "MT",
        },
        {
          name: "Martinique",
          value: "Martinique",
          code: "MQ",
        },
        {
          name: "Mauritania",
          value: "Mauritania",
          code: "MR",
        },
        {
          name: "Mauritius",
          value: "Mauritius",
          code: "MU",
        },
        {
          name: "Mayotte",
          value: "Mayotte",
          code: "YT",
        },
        {
          name: "Mexico",
          value: "Mexico",
          code: "MX",
        },
        {
          name: "Moldova",
          value: "Moldova",
          code: "MD",
        },
        {
          name: "Monaco",
          value: "Monaco",
          code: "MC",
        },
        {
          name: "Mongolia",
          value: "Mongolia",
          code: "MN",
        },
        {
          name: "Montenegro",
          value: "Montenegro",
          code: "ME",
        },
        {
          name: "Montserrat",
          value: "Montserrat",
          code: "MS",
        },
        {
          name: "Morocco",
          value: "Morocco",
          code: "MA",
        },
        {
          name: "Mozambique",
          value: "Mozambique",
          code: "MZ",
        },
        {
          name: "Myanmar (Burma)",
          value: "Myanmar (Burma)",
          code: "MM",
        },
        {
          name: "Namibia",
          value: "Namibia",
          code: "NA",
        },
        {
          name: "Nauru",
          value: "Nauru",
          code: "NR",
        },
        {
          name: "Nepal",
          value: "Nepal",
          code: "NP",
        },
        {
          name: "Netherlands",
          value: "Netherlands",
          code: "NL",
        },
        {
          name: "New Caledonia",
          value: "New Caledonia",
          code: "NC",
        },
        {
          name: "New Zealand",
          value: "New Zealand",
          code: "NZ",
        },
        {
          name: "Nicaragua",
          value: "Nicaragua",
          code: "NI",
        },
        {
          name: "Niger",
          value: "Niger",
          code: "NE",
        },
        {
          name: "Nigeria",
          value: "Nigeria",
          code: "NG",
        },
        {
          name: "Niue",
          value: "Niue",
          code: "NU",
        },
        {
          name: "North Macedonia",
          value: "North Macedonia",
          code: "MK",
        },
        {
          name: "Norway",
          value: "Norway",
          code: "NO",
        },
        {
          name: "Oman",
          value: "Oman",
          code: "OM",
        },
        {
          name: "Pakistan",
          value: "Pakistan",
          code: "PK",
        },
        {
          name: "Palestinian Territories",
          value: "Palestinian Territories",
          code: "PS",
        },
        {
          name: "Panama",
          value: "Panama",
          code: "PA",
        },
        {
          name: "Papua New Guinea",
          value: "Papua New Guinea",
          code: "PG",
        },
        {
          name: "Paraguay",
          value: "Paraguay",
          code: "PY",
        },
        {
          name: "Peru",
          value: "Peru",
          code: "PE",
        },
        {
          name: "Philippines",
          value: "Philippines",
          code: "PH",
        },
        {
          name: "Pitcairn Islands",
          value: "Pitcairn Islands",
          code: "PN",
        },
        {
          name: "Poland",
          value: "Poland",
          code: "PL",
        },
        {
          name: "Portugal",
          value: "Portugal",
          code: "PT",
        },
        {
          name: "Puerto Rico",
          value: "Puerto Rico",
          code: "PR",
        },
        {
          name: "Qatar",
          value: "Qatar",
          code: "QA",
        },
        {
          name: "Réunion",
          value: "Réunion",
          code: "RE",
        },
        {
          name: "Romania",
          value: "Romania",
          code: "RO",
        },
        {
          name: "Russia",
          value: "Russia",
          code: "RU",
        },
        {
          name: "Rwanda",
          value: "Rwanda",
          code: "RW",
        },
        {
          name: "Samoa",
          value: "Samoa",
          code: "WS",
        },
        {
          name: "San Marino",
          value: "San Marino",
          code: "SM",
        },
        {
          name: "São Tomé and Príncipe",
          value: "São Tomé and Príncipe",
          code: "ST",
        },
        {
          name: "Saudi Arabia",
          value: "Saudi Arabia",
          code: "SA",
        },
        {
          name: "Senegal",
          value: "Senegal",
          code: "SN",
        },
        {
          name: "Serbia",
          value: "Serbia",
          code: "RS",
        },
        {
          name: "Seychelles",
          value: "Seychelles",
          code: "SC",
        },
        {
          name: "Sierra Leone",
          value: "Sierra Leone",
          code: "SL",
        },
        {
          name: "Singapore",
          value: "Singapore",
          code: "SG",
        },
        {
          name: "Sint Maarten",
          value: "Sint Maarten",
          code: "SX",
        },
        {
          name: "Slovakia",
          value: "Slovakia",
          code: "SK",
        },
        {
          name: "Slovenia",
          value: "Slovenia",
          code: "SI",
        },
        {
          name: "Solomon Islands",
          value: "Solomon Islands",
          code: "SB",
        },
        {
          name: "Somalia",
          value: "Somalia",
          code: "SO",
        },
        {
          name: "South Africa",
          value: "South Africa",
          code: "ZA",
        },
        {
          name: "South Georgia and South Sandwich Islands",
          value: "South Georgia and South Sandwich Islands",
          code: "GS",
        },
        {
          name: "South Korea",
          value: "South Korea",
          code: "KR",
        },
        {
          name: "South Sudan",
          value: "South Sudan",
          code: "SS",
        },
        {
          name: "Spain",
          value: "Spain",
          code: "ES",
        },
        {
          name: "Sri Lanka",
          value: "Sri Lanka",
          code: "LK",
        },
        {
          name: "St. Barthélemy",
          value: "St. Barthélemy",
          code: "BL",
        },
        {
          name: "St. Helena",
          value: "St. Helena",
          code: "SH",
        },
        {
          name: "St. Kitts and Nevis",
          value: "St. Kitts and Nevis",
          code: "KN",
        },
        {
          name: "St. Lucia",
          value: "St. Lucia",
          code: "LC",
        },
        {
          name: "St. Martin",
          value: "St. Martin",
          code: "MF",
        },
        {
          name: "St. Pierre and Miquelon",
          value: "St. Pierre and Miquelon",
          code: "PM",
        },
        {
          name: "St. Vincent and Grenadines",
          value: "St. Vincent and Grenadines",
          code: "VC",
        },
        {
          name: "Sudan",
          value: "Sudan",
          code: "SD",
        },
        {
          name: "Suriname",
          value: "Suriname",
          code: "SR",
        },
        {
          name: "Svalbard and Jan Mayen",
          value: "Svalbard and Jan Mayen",
          code: "SJ",
        },
        {
          name: "Sweden",
          value: "Sweden",
          code: "SE",
        },
        {
          name: "Switzerland",
          value: "Switzerland",
          code: "CH",
        },
        {
          name: "Taiwan",
          value: "Taiwan",
          code: "TW",
        },
        {
          name: "Tajikistan",
          value: "Tajikistan",
          code: "TJ",
        },
        {
          name: "Tanzania",
          value: "Tanzania",
          code: "TZ",
        },
        {
          name: "Thailand",
          value: "Thailand",
          code: "TH",
        },
        {
          name: "Timor-Leste",
          value: "Timor-Leste",
          code: "TL",
        },
        {
          name: "Togo",
          value: "Togo",
          code: "TG",
        },
        {
          name: "Tokelau",
          value: "Tokelau",
          code: "TK",
        },
        {
          name: "Tonga",
          value: "Tonga",
          code: "TO",
        },
        {
          name: "Trinidad and Tobago",
          value: "Trinidad and Tobago",
          code: "TT",
        },
        {
          name: "Tristan da Cunha",
          value: "Tristan da Cunha",
          code: "TA",
        },
        {
          name: "Tunisia",
          value: "Tunisia",
          code: "TN",
        },
        {
          name: "Türkiye",
          value: "Türkiye",
          code: "TR",
        },
        {
          name: "Turkmenistan",
          value: "Turkmenistan",
          code: "TM",
        },
        {
          name: "Turks and Caicos Islands",
          value: "Turks and Caicos Islands",
          code: "TC",
        },
        {
          name: "Tuvalu",
          value: "Tuvalu",
          code: "TV",
        },
        {
          name: "Uganda",
          value: "Uganda",
          code: "UG",
        },
        {
          name: "Ukraine",
          value: "Ukraine",
          code: "UA",
        },
        {
          name: "United Arab Emirates",
          value: "United Arab Emirates",
          code: "AE",
        },
        {
          name: "United Kingdom",
          value: "United Kingdom",
          code: "GB",
        },
        {
          name: "United States",
          value: "United States",
          code: "US",
        },
        {
          name: "Uruguay",
          value: "Uruguay",
          code: "UY",
        },
        {
          name: "Uzbekistan",
          value: "Uzbekistan",
          code: "UZ",
        },
        {
          name: "Vanuatu",
          value: "Vanuatu",
          code: "VU",
        },
        {
          name: "Vatican City",
          value: "Vatican City",
          code: "VA",
        },
        {
          name: "Venezuela",
          value: "Venezuela",
          code: "VE",
        },
        {
          name: "Vietnam",
          value: "Vietnam",
          code: "VN",
        },
        {
          name: "Wallis and Futuna",
          value: "Wallis and Futuna",
          code: "WF",
        },
        {
          name: "Western Sahara",
          value: "Western Sahara",
          code: "EH",
        },
        {
          name: "Yemen",
          value: "Yemen",
          code: "YE",
        },
        {
          name: "Zambia",
          value: "Zambia",
          code: "ZM",
        },
        {
          name: "Zimbabwe",
          value: "Zimbabwe",
          code: "ZW",
        },
      ],

      displayBasic: false,
      displayOpenend: false,
      displayItem: {},
      windowSize: window.innerWidth,
      loading: false,
      slideData: [
        {
          desktopImage: require("@/assets/images/worldappeal/flyer/1.jpg"),
          mobileImage: require("@/assets/images/worldappeal/flyer/web-cover2.jpg"),
          textRight: false,
        },
        {
          desktopImage: require("@/assets/images/worldappeal/flyer/1.jpg"),
          mobileImage: require("@/assets/images/worldappeal/flyer/web-cover2.jpg"),
          textRight: false,
        },
        {
          desktopImage: require("@/assets/images/worldappeal/flyer/1.jpg"),
          mobileImage: require("@/assets/images/worldappeal/flyer/web-cover2.jpg"),
          textRight: false,
        },
      ],
      appealData: [
        {
          title: "Ramadan Projects & Eid Gifts",
          category: "ramadan-projects",
          image: require("@/assets/images/worldappeal/flyer/Layer 3.jpg"),
          content:
            "Education is a key that unlocks many opportunities. It is a way out of poverty; a driving force for social change; and an important aspect of personal development and welfare. This is why it is one of the main area’s of focus for us here at World Appeal.",
        },
        // {
        //   title: "Emergency Relief in Middle East",
        //   category: "emergency-relief",
        //   image: require("@/assets/images/worldappeal/flyer/Layer 8.jpg"),
        //   content:
        //     "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        // },
        {
          title: "Water Projects",
          category: "water-projects",
          image: require("@/assets/images/worldappeal/flyer/Layer 2.jpg"),
          content:
            "Water is a basic necessity for life, unfortunately, in 2021 many people spend their entire day searching for clean drinking water and in some cases are unsuccessful and have to use contaminated water sources. Help us install wells and pumps.",
        },
        {
          title: "Child Sponsorship",
          category: "child-sponsorship",
          image: require("@/assets/images/worldappeal/flyer/Layer 5.jpg"),
          content:
            "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        },
        {
          title: "Mosque Projects",
          category: "mosque-projects",
          image: require("@/assets/images/worldappeal/flyer/Layer 32.jpg"),
          content:
            "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        },
      ],
      currentProject: "",
      currentData: [],
      currentItem: {},
      subData: [
        {
          title: "Fitrana",
          image: require("@/assets/images/worldappeal/flyer/Layer 3.jpg"),
          content:
            "It is the responsibility of the head of each household pay £4 for every member in the household before Eid-ul-Fitr Prayer.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/bIY8wVa4S8IfaWI289",
          value: 1,
          amount: 4,
        },
        {
          title: "Fidayah (£4 for every fast missed)",
          image: require("@/assets/images/worldappeal/flyer/Layer 4.jpg"),
          content:
            "If any person is medically exempt from fasting through-out the month of Ramadan and he/she is unable to make up for the missed fasts, then they must pay Fidayah for the entire month.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/bIY28x3Gu7Ebc0MaET",
          value: 1,
          amount: 4,
        },
        {
          title: "Sahoor and Iftar meals - (£60 for entire month)",
          image: require("@/assets/images/worldappeal/flyer/Layer 6.jpg"),
          content:
            "Feed a fasting person two meals throughout the month of ramadan.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/28o28xb8W1fNd4QcN0",
          value: 1,
          amount: 2,
        },
        {
          title: "Ramadan Food Parcel - (£50 for family of 5)",
          image: require("@/assets/images/worldappeal/flyer/Layer 8.jpg"),
          content:
            "Delivering essential food parcels to support a family of 5 in need for the entire month of Ramadan.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/fZe7sR2Cqe2zfcY28o",
          value: 1,
          amount: 50,
        },
        {
          title: "Sahoor and Iftar Meals - £60",
          image: require("@/assets/images/worldappeal/flyer/Layer 7.jpg"),
          content:
            "Provide daily meals for 1 person for Sahoor and Iftar throughout the entire month of Ramadan.",
          category: "emergency-relief",
          link: "https://buy.stripe.com/aEU14t7WKe2zd4QcMM",
          value: 1,
          amount: 4,
        },
        {
          title: "Emergency Aid - £100",
          image: require("@/assets/images/worldappeal/flyer/meals.jpg"),
          content:
            "Delivering vital emergency aid wherever the need is most, including food, water and basic essentials.",
          category: "emergency-relief",
          link: "https://buy.stripe.com/aEU14t7WKe2zd4QcMM",
          value: 1,
          amount: 4,
        },
        {
          title: "Small Hand Pump - £150",
          image: require("@/assets/images/worldappeal/flyer/Layer 2.jpg"),
          content:
            "Provide a community of 50 people with clean and safe drinking water in Asia.",
          category: "water-projects",
          link: "https://buy.stripe.com/4gwbJ7dh49Mj2qc28c",
          value: 1,
          amount: 150,
        },
        {
          title: "Medium Water Pump - £450",
          image: require("@/assets/images/worldappeal/flyer/Layer 11.jpg"),
          content:
            "Build a water pump to provide water for more than 150 people.",
          category: "water-projects",
          link: "https://buy.stripe.com/eVaaF3el8gaH1m8fZ3",
          value: 1,
          amount: 450,
        },
        {
          title: "Large Water Well - £3000",
          image: require("@/assets/images/worldappeal/flyer/water-story.png"),
          content: "Build a water well for entire village in Africa.",
          category: "water-projects",
          link: "https://buy.stripe.com/dR66oN3Gue2z8OA006",
          value: 1,
          amount: 3000,
        },
        {
          title: "Orphan and Needy - £35 per month and £420 per year",
          image: require("@/assets/images/worldappeal/flyer/Layer 5.jpg"),
          content:
            "Provide monthly support to an orphan and needy child with education, clothing, food and living expenses.",
          category: "child-sponsorship",
          link: "https://worldappeal.enthuse.com/orphan",
          value: 1,
          amount: 35,
          showMore: true,
          moreItems: [
            {
              title: "Donate one time (£35)",
              link: "https://buy.stripe.com/3cs4gFcd08If1m828f",
            },
            {
              title: "Donate one time (£420)",
              link: "https://buy.stripe.com/7sIeVj90O7Ebc0MbIQ",
            },
            {
              title: "Donate every month",
              link: "https://worldappeal.enthuse.com/orphan",
            },
          ],
        },
        {
          title: "Hifz e Quran - £25 per month and £300 per year",
          image: require("@/assets/images/worldappeal/flyer/Layer 61.jpg"),
          content:
            "Support a child to memorise the holy quran, this is a 4 year program.",
          category: "child-sponsorship",
          link: "https://worldappeal.enthuse.com/Hifz",
          value: 1,
          amount: 25,
          showMore: true,
          moreItems: [
            {
              title: "Donate one time (£25)",
              link: "https://buy.stripe.com/14k5kJcd03nV4yk6oy",
            },
            {
              title: "Donate one time (£300)",
              link: "https://buy.stripe.com/7sI8wVel83nVd4Q14f",
            },
            {
              title: "Donate every month",
              link: "https://worldappeal.enthuse.com/Hifz",
            },
          ],
        },
        {
          title: "Eid Gift - £15",
          image: require("@/assets/images/worldappeal/flyer/Layer 9.jpg"),
          content:
            "Provide a child in need with an Eid Gift (food, clothes and toys).",
          category: "child-sponsorship",
          link: "https://buy.stripe.com/fZe7sR5OCcYv3ugeV3",
          value: 1,
          amount: 15,
        },
        {
          title: "Small Mosque - £6000",
          image: require("@/assets/images/worldappeal/flyer/Layer 32.jpg"),
          content: "Build a mosque for 140 people to worship.",
          category: "mosque-projects",
          link: "https://buy.stripe.com/4gw8wV90O2jRaWIbIU",
          value: 1,
          amount: 6000,
        },
        {
          title: "Medium Mosque - £7500",
          image: require("@/assets/images/worldappeal/flyer/medium-mosque.jpeg"),
          content:
            "Build a mosque for 230 people to worship and learn about deen.",
          category: "mosque-projects",
          link: "https://buy.stripe.com/9AQ9AZ3Gu4rZ2qc28l",
          value: 1,
          amount: 7500,
        },
      ],
      pageData: [],
      subLoading: false,
      showSubs: false,
      request: false,
      // receiver: "aseefjawaid123@gmail.com",
      receiver: "info@worldappeal.org.uk",
      mailObj: {
        name: "",
        email: "",
        message: "",
        address: "",
        phone: "",
        job: "",
        subject: "",
      },
      stripeElements: null,
      // publishableKey: "pk_test_ihRoO7Sbe6iYWiaOqz4kEvCA",
      publishableKey: "pk_live_gJW0Uty4GYps2Yd2uYDWoHA1",
      card: null,
      processing: false,
      stripe: null,
      quickData: [10, 30, 50, 100, 200, "Custom"],
      quickAmount: 0,
      selectedAmount: 0,
      typeOptions: ["General Donation", "Sadqah", "Zakat", "Where most needed"],
      paymentObj: {
        recurring: "Single Donation",
      },
      otherLoading: false,
      searchQuery: "",
      searching: false,
      otherObj: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        donationType: "General Donation",
        cardName: "",
        recurring: "Single Donation",
        anonymous: false,
        address: {
          addressline1: "",
          addressline2: "",
          summaryline: "",
          subbuildingname: "",
          buildingname: "",
          number: "",
          premise: "",
          street: "",
          posttown: "",
          county: "",
          postcode: "",
        },
        giftAid: true,
        country: "United Kingdom",
        whatsapp: true,
        post: true,
        emailCheck: true,
        sms: true,
      },
      suggestionList: [],
      suggestedAddress: "",
      elementsArray: [],
      transObjOther: {},
      transObjP: {},
      projectID: "a10P30000042rVlIAI",
      fundID: "701P300000CcQ2eIAF",
    };
  },
  watch: {
    $route: function () {
      if (this.stripeElements) {
        this.card.destroy();
        this.stripeElements = null;
        this.card = null;
      }
      // this.setSub();
      if (!this.displayOpenend) {
        this.loadSub();
      }
      this.displayOpenend = false;
      // this.loadData();
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.stripe = Stripe(this.publishableKey);
  },
  beforeDestroy() {
    if (this.stripeElements) {
      this.card.destroy();
    }
    if (this.elementsArray.length > 0) {
      this.elementsArray.forEach((elem) => {
        if (elem.el) elem.card.destroy();
      });
    }
  },
  created() {
    this.loadData();
    // this.$store.commit("setSlides", this.slideData);
    // this.setSub();
    this.loadSub();
  },
  computed: {
    screenSize() {
      return this.windowSize <= 479;
    },
  },
  methods: {
    isVideo(url) {
      return /\.(mp4)$/i.test(url);
    },
    openQLink() {
      window.open(
        "https://qurbanigiving.com/charities/world-appeal/animal",
        "_blank"
      );
    },
    openFaq(items, index) {
      items = items.map((el, ind) => {
        if (ind == index) el.state = !el.state;
        else el.state = false;
        return el;
      });
    },
    initStripe() {
      this.stripeElements = this.stripe.elements({
        mode: "payment",
        currency: "gbp",
        // amount: this.getAmount(this.displayItem),
        amount: this.displayItem.amount,
        paymentMethodTypes: ["card"],
      });
      // console.log(this.stripeElements);
      // Style Object documentation here: https://stripe.com/docs/js/appendix/style
      const style = {
        base: {
          color: "black",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "18px",
          padding: "10px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      this.card = this.stripeElements.create("payment", {
        fields: {
          billingDetails: {
            address: "never",
          },
        },
      });
      this.card.mount("#card-element");
      // this.card = this.stripeElements.create("card", { style });
      // this.card.mount("#card-element");
      this.card.on("loaderror", this.handleLoadError);
    },
    handleLoadError(e) {
      console.log(e);
      this.stripeElements = null;
      this.displayBasic = false;
      this.toast.error("Something went wrong, Please try again.");
    },
    handleResize() {
      this.windowSize = window.innerWidth;
      // console.log("ss");
    },
    setSub() {
      let obj = this.appealData.find(
        (el) => el.category == this.$route.query.project
      );
      // console.log(obj);
      if (obj) {
        this.currentData = this.subData.filter(
          (el) => el.category == obj.category
        );
        this.currentProject = obj.title;
      } else {
        this.removeSub();
      }
    },
    filterRoute(cat) {
      return {
        path: this.$route.path,
        query: { project: cat },
      };
    },
    removeSub() {
      this.currentProject = "";
      this.currentData = [];
    },
    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";
    },
    toggleOptions(text, item) {
      const sec = document.querySelector("#donate-options");
      this.currentItem = item;
      sec.style.display = text == "open" ? "flex" : "none";
    },
    openDialog(item) {
      if (item.value == 0 || item.value % 1 !== 0) {
        this.toast.error("Please enter correct value");
      } else if (item.value > 999) {
        this.toast.error(
          "Quantity exceeds maximum limit of 999. Please adjust the quantity."
        );
      } else {
        this.displayItem = item;
        this.displayBasic = true;
        this.displayOpenend = true;
        // console.log(item.actionType);
        // if (item.actionType == "payment") {
        //   let q = {};
        //   if (this.$route.query.project) q.project = this.$route.query.project;
        //   q.type = "p";
        //   q.tID = item.id;
        //   q.sf = item.saleforceID ? item.saleforceID : this.projectID;
        //   this.$router.push({
        //     path: this.$route.path,
        //     query: q,
        //   });
        //   this.paymentObj.recurring = "Single Donation";
        // }

        this.paymentObj = {
          id: 0,
          firstName: "",
          lastName: "",
          email: "",
          contact: "",
          donationType: "General Donation",
          cardName: "",
          recurring: this.paymentObj.recurring,
          anonymous: false,
          giftAid: true,
          country: "United Kingdom",
          whatsapp: true,
          post: true,
          emailCheck: true,
          sms: true,
          address: {
            addressline1: "",
            addressline2: "",
            summaryline: "",
            subbuildingname: "",
            buildingname: "",
            number: "",
            premise: "",
            street: "",
            posttown: "",
            county: "",
            postcode: "",
          },
        };
        // console.log(this.paymentObj);
        setTimeout(() => {
          this.initStripe();
        }, 300);
      }
    },
    checkValue(item) {
      // console.log(item.value);
      if (item.value == 0 || item.value % 1 !== 0) {
        return "£0";
      }
      return `£${item.amount * item.value}`;
    },
    getAmount(item) {
      let total = item.amount * item.value;
      // if (this.paymentObj.giftAid) {
      //   return (total * 0.25 + total).toFixed(2);
      //   // return parseInt(total * 0.25 + total);
      // }
      return total;
    },
    getChartValue(item) {
      let total = item.amount * item.value;
      let obj = {
        label: [`Donation (£${total})`],
        perc: [100],
        colors: ["#3367b7"],
      };

      if (this.paymentObj.giftAid) {
        obj = {
          label: [
            `Gift Aid (£${(total * 0.25).toFixed(2)})`,
            `Donation (£${total})`,
          ],
          perc: [25, 75],
          colors: ["#4aa567", "#3367b7"],
        };
      }
      return obj;
    },
    handleError(err) {
      const msgDiv = document.querySelector("#card-error");
      msgDiv.textContent = err.message;

      this.processing = false;
    },
    async getDetails(id) {
      try {
        const res = await fetch(
          `https://dc.appick.io/stripeWorldAppeal/payment/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!res.ok) {
          return "";
        } else {
          const { paymentMethod: paymentMethod } = await res.json();
          return paymentMethod;
        }
      } catch (error) {
        return "";
      }
    },
    async createIntent() {
      try {
        const res = await fetch("https://dc.appick.io/stripeWorldAppeal", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: this.getAmount(this.displayItem),
          }),
        });
        if (!res.ok) {
          this.toast.error("Something went wrong!");
          return "";
        } else {
          const { clientSecret: clientSecret } = await res.json();
          return clientSecret;
        }
      } catch (error) {
        this.toast.error("Something went wrong!");
        return "";
      }
    },
    async formSubmit(e) {
      // console.log(this.paymentObj);
      if (this.processing) {
        return;
      }
      this.processing = true;

      const { error: submitError } = await this.stripeElements.submit();
      // console.log(submitError);

      if (submitError) {
        this.handleError(submitError);
        return;
      }

      // this.processing = false;
      // this.toast.error("Something went wrong!");
      // return;

      const clientSecret = await this.createIntent();
      // console.log(clientSecret);

      if (clientSecret != "") {
        const { paymentIntent, error } = await this.stripe.confirmPayment({
          elements: this.stripeElements,
          clientSecret,
          redirect: "if_required",
          confirmParams: {
            // return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                address: {
                  city: this.paymentObj.address.posttown,
                  country: this.countries.find(
                    (el) => el.value == this.paymentObj.country
                  ).code,
                  line1: this.paymentObj.address.addressline1,
                  line2: this.paymentObj.address.addressline2,
                  postal_code: this.paymentObj.address.postcode,
                  state: this.paymentObj.address.county,
                },
                email: this.paymentObj.email,
                name:
                  this.paymentObj.firstName + " " + this.paymentObj.lastName,
                phone: this.paymentObj.contact,
              },
            },
          },
        });
        // console.log(error);
        if (error) {
          this.handleError(error);
        } else {
          this.toast.success("Thank You for your donation");
          // console.log(paymentIntent);

          this.processing = false;
          this.displayBasic = false;

          const details = await this.getDetails(paymentIntent.payment_method);
          // console.log(details);

          this.transObjP = {
            id: 0,
            Email__c: this.paymentObj.email,
            First_Name__c: this.paymentObj.firstName,
            Last_Name__c: this.paymentObj.lastName,
            Phone__c: this.paymentObj.contact,
            UK_Tax_Payer__c: this.paymentObj.giftAid,
            Address_Line_1__c: this.paymentObj.address.addressline1,
            Address_Line_2__c: this.paymentObj.address.addressline2,
            City__c: this.paymentObj.address.posttown,
            State__c: this.paymentObj.address.county,
            Post_Code__c: this.paymentObj.address.postcode,
            Country__c: this.paymentObj.country,
            Summary_Line__c: this.paymentObj.address.summaryline,
            Sub_building_Name__c: this.paymentObj.address.subbuildingname,
            House_Number__c: this.paymentObj.address.number || "",
            Premise__c: this.paymentObj.address.premise,
            Address_Verified__c: this.paymentObj.country == "United Kingdom",
            Status__c: "Closed Won",
            Currency__c: "GBP",
            Transaction_Amount__c: this.getAmount(this.displayItem),
            Transaction_Date__c: new Date().toJSON(),
            Transaction_Type__c: this.paymentObj.recurring,
            Transaction_Details__c: this.displayItem.title,
            Payment_Method__c: "Credit Card",
            Completed_Date__c: new Date().toJSON(),
            Is_Anonymous_Donation__c: this.paymentObj.anonymous,
            Currency_Symbol__c: "£",
            Post__c: this.paymentObj.post,
            Whatsapp__c: this.paymentObj.whatsapp,
            SMS__c: this.paymentObj.sms,
            W_Email__c: this.paymentObj.emailCheck,
            Credit_Card_Numer__c: details.card.last4 || "",
            CC_Expiry_Month__c: details.card.exp_month.toString() || "",
            CC_Expiry_Year__c: details.card.exp_year.toString() || "",
            Stripe_Transaction_Id__c: paymentIntent.id,
            Stripe_Recurring_Id__c: "",
            Stripe_Card_Id__c: "",
            Stripe_Customer_ID__c: "",
            Campaign__c: null,
            Project__c: null,
            RecordTypeId: "",
            CMS_Id__c: "",
            saleforceID: "",
            Donation_Type: this.paymentObj.donationType,
          };

          let tt = this.$route.query.type;
          if (tt && tt == "p" && this.$route.query.sf) {
            this.transObjP.Project__c = this.$route.query.sf;
            this.transObjP.Campaign__c = null;
          } else if (tt && tt == "c") {
            if (this.$route.query.sf) {
              this.transObjP.Campaign__c = this.$route.query.sf;
              this.transObjP.Project__c = null;
            } else {
              this.transObjP.Campaign__c = this.fundID;
              this.transObjP.Project__c = null;
            }
            if (this.$route.query.tID) {
              this.getFund(
                this.$route.query.tID,
                this.transObjP.Transaction_Amount__c
              );
            }
          } else {
            this.transObjP.Project__c = this.projectID;
            this.transObjP.Campaign__c = null;
          }

          // console.log(this.transObjP);
          this.saveT(this.transObjP);

          this.paymentObj = {
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            contact: "",
            donationType: this.paymentObj.donationType,
            cardName: "",
            recurring: this.paymentObj.recurring,
            anonymous: false,
            giftAid: this.paymentObj.giftAid,
            country: this.paymentObj.country,
            whatsapp: true,
            post: true,
            emailCheck: true,
            sms: true,
            address: {
              addressline1: "",
              addressline2: "",
              summaryline: "",
              subbuildingname: "",
              buildingname: "",
              number: "",
              premise: "",
              street: "",
              posttown: "",
              county: "",
              postcode: "",
            },
          };

          this.card.clear();
        }
      } else {
        this.processing = false;
      }
    },
    getQuickData(val) {
      var result = [];
      for (var i = 1; i <= 5; i++) {
        result.push(val * i);
      }
      result.push("Custom");
      return result;
    },
    setAmount(item, val, ind) {
      // console.log(item);
      // this.selectedAmount = val;
      item.selected = val;
      if (val == "Custom") {
        this.$refs["amount" + ind][0].focus();
      } else item.amount = val;
    },
    donateSubmit(item) {
      // console.log(item);
      if (item.amount <= 0) {
        this.toast.error("Please enter correct amount.");
      } else {
        // item.amount = this.quickAmount;
        item.value = 1;
        this.openDialog(item);
      }
    },
    countryChange() {
      this.otherObj.address = {
        addressline1: "",
        addressline2: "",
        summaryline: "",
        subbuildingname: "",
        buildingname: "",
        number: "",
        premise: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      };
      if (this.otherObj.country != "United Kingdom")
        this.otherObj.giftAid = false;
    },
    setAddress() {
      console.log(this.suggestedAddress);
      this.otherObj.address = this.suggestedAddress;
    },
    async searchAddress() {
      if (this.searchQuery != "") {
        let api_key = "PCWP4-Y7X4R-YBXS2-76ZL3";
        let page = 0;

        this.searching = true;

        var url =
          "https://ws.postcoder.com/pcw/" +
          api_key +
          "/address/uk/" +
          this.searchQuery +
          "?lines=2&page=" +
          page;

        let config = {
          method: "get",
          url: url,
        };
        axios(config)
          .then((response) => {
            // console.log(response);
            this.suggestionList = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.suggestionList = [];
          })
          .finally(() => (this.searching = false));
      }
    },
    countryChangeP() {
      this.paymentObj.address = {
        addressline1: "",
        addressline2: "",
        summaryline: "",
        subbuildingname: "",
        buildingname: "",
        number: "",
        premise: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      };
      if (this.paymentObj.country != "United Kingdom")
        this.paymentObj.giftAid = false;
    },
    setAddressP() {
      console.log(this.suggestedAddress);
      this.paymentObj.address = this.suggestedAddress;
    },
    getCardAmount(item) {
      let total = item.amount;
      // console.log(total);
      // if (total && this.otherObj.giftAid) {
      //   return (total * 0.25 + total).toFixed(2);
      //   // return parseInt(total * 0.25 + total);
      // }
      return total;
    },
    getCardChartValue(item) {
      let total = item.amount;
      let obj = {
        label: [`Donation (£${total})`],
        perc: [100],
        colors: ["#3367b7"],
      };

      if (this.otherObj.giftAid) {
        obj = {
          label: [
            `Gift Aid (£${(total * 0.25).toFixed(2)})`,
            `Donation (£${total})`,
          ],
          perc: [25, 75],
          colors: ["#4aa567", "#3367b7"],
        };
      }
      return obj;
    },
    Next(item, ind) {
      if (item.amount <= 0) {
        this.toast.error("Please enter correct amount.");
      } else {
        item.showInfo = true;
        setTimeout(() => {
          let total = this.getCardAmount(item);

          if (this.elementsArray[ind].el) {
            this.elementsArray[ind].el.update({ amount: total });
          } else {
            this.elementsArray[ind].el = this.stripe.elements({
              mode: "payment",
              currency: "gbp",
              amount: total,
              paymentMethodTypes: ["card"],
            });

            this.elementsArray[ind].card = this.elementsArray[ind].el.create(
              "payment",
              {
                fields: {
                  billingDetails: {
                    address: "never",
                  },
                },
              }
            );
            this.elementsArray[ind].card.mount(`#card-element${ind}`);
            this.elementsArray[ind].card.on("loaderror", handleCardLoadError);
          }
          // console.log(this.elementsArray[ind]);
        }, 300);
      }
      // console.log(item);
      const handleCardLoadError = (e) => {
        console.log(e);
        this.elementsArray[ind].el = null;
        item.showInfo = false;
        this.toast.error("Something went wrong, Please try again.");
      };
    },
    handleOtherError(err, ind) {
      const msgDiv = document.querySelector(`#card-error${ind}`);
      msgDiv.textContent = err.message;

      this.otherLoading = false;
    },
    async createIntentOther(item) {
      try {
        const res = await fetch("https://dc.appick.io/stripeWorldAppeal", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: this.getCardAmount(item),
          }),
        });
        if (!res.ok) {
          this.toast.error("Something went wrong!");
          return "";
        } else {
          const { clientSecret: clientSecret } = await res.json();
          return clientSecret;
        }
      } catch (error) {
        this.toast.error("Something went wrong!");
        return "";
      }
    },
    async otherSubmit(item, ind) {
      // console.log(item, this.otherObj);

      if (this.otherLoading) {
        return;
      }
      this.otherLoading = true;

      const { error: submitError } = await this.elementsArray[ind].el.submit();
      // console.log(submitError);

      if (submitError) {
        this.handleOtherError(submitError, ind);
        return;
      }

      // this.otherLoading = false;
      // this.toast.error("Something went wrong!");
      // return;

      const clientSecret = await this.createIntentOther(item);
      // console.log(clientSecret);

      if (clientSecret != "") {
        const { paymentIntent, error } = await this.stripe.confirmPayment({
          elements: this.elementsArray[ind].el,
          clientSecret,
          redirect: "if_required",
          confirmParams: {
            // return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                address: {
                  city: this.otherObj.address.posttown,
                  country: this.countries.find(
                    (el) => el.value == this.otherObj.country
                  ).code,
                  line1: this.otherObj.address.addressline1,
                  line2: this.otherObj.address.addressline2,
                  postal_code: this.otherObj.address.postcode,
                  state: this.otherObj.address.county,
                },
                email: this.otherObj.email,
                name: this.otherObj.firstName + " " + this.otherObj.lastName,
                phone: this.otherObj.contact,
              },
            },
          },
        });
        // console.log(error);
        if (error) {
          this.handleOtherError(error, ind);
        } else {
          this.toast.success("Thank You for your donation");
          this.otherLoading = false;

          // console.log(paymentIntent);
          const details = await this.getDetails(paymentIntent.payment_method);
          // console.log(details);

          this.transObjOther = {
            id: 0,
            Email__c: this.otherObj.email,
            First_Name__c: this.otherObj.firstName,
            Last_Name__c: this.otherObj.lastName,
            Phone__c: this.otherObj.contact,
            UK_Tax_Payer__c: this.otherObj.giftAid,
            Address_Line_1__c: this.otherObj.address.addressline1,
            Address_Line_2__c: this.otherObj.address.addressline2,
            City__c: this.otherObj.address.posttown,
            State__c: this.otherObj.address.county,
            Post_Code__c: this.otherObj.address.postcode,
            Country__c: this.otherObj.country,
            Summary_Line__c: this.otherObj.address.summaryline,
            Sub_building_Name__c: this.otherObj.address.subbuildingname,
            House_Number__c: this.otherObj.address.number || "",
            Premise__c: this.otherObj.address.premise,
            Address_Verified__c: this.otherObj.country == "United Kingdom",
            Status__c: "Closed Won",
            Currency__c: "GBP",
            Transaction_Amount__c: this.getCardAmount(item),
            Transaction_Date__c: new Date().toJSON(),
            Transaction_Type__c: this.otherObj.recurring,
            Transaction_Details__c: item.title,
            Payment_Method__c: "Credit Card",
            Completed_Date__c: new Date().toJSON(),
            Is_Anonymous_Donation__c: this.otherObj.anonymous,
            Currency_Symbol__c: "£",
            Post__c: this.otherObj.post,
            Whatsapp__c: this.otherObj.whatsapp,
            SMS__c: this.otherObj.sms,
            W_Email__c: this.otherObj.emailCheck,
            Credit_Card_Numer__c: details.card.last4 || "",
            CC_Expiry_Month__c: details.card.exp_month.toString() || "",
            CC_Expiry_Year__c: details.card.exp_year.toString() || "",
            Stripe_Transaction_Id__c: paymentIntent.id,
            Stripe_Recurring_Id__c: "",
            Stripe_Card_Id__c: "",
            Stripe_Customer_ID__c: "",
            Campaign__c: null,
            Project__c: null,
            RecordTypeId: "",
            CMS_Id__c: "",
            saleforceID: "",
            Donation_Type: this.otherObj.donationType,
          };

          let tt = this.$route.query.type;
          if (tt && tt == "p" && this.$route.query.sf) {
            this.transObjOther.Project__c = this.$route.query.sf;
            this.transObjOther.Campaign__c = null;
          } else if (tt && tt == "c") {
            if (this.$route.query.sf) {
              this.transObjOther.Campaign__c = this.$route.query.sf;
              this.transObjOther.Project__c = null;
            } else {
              this.transObjOther.Campaign__c = this.fundID;
              this.transObjOther.Project__c = null;
            }
            if (this.$route.query.tID) {
              this.getFund(
                this.$route.query.tID,
                this.transObjOther.Transaction_Amount__c
              );
            }
          } else {
            this.transObjOther.Project__c = this.projectID;
            this.transObjOther.Campaign__c = null;
          }

          // console.log(this.transObjOther);
          this.saveT(this.transObjOther);

          this.otherObj = {
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            contact: "",
            donationType: this.otherObj.donationType,
            cardName: "",
            recurring: this.otherObj.recurring,
            anonymous: false,
            address: {
              addressline1: "",
              addressline2: "",
              summaryline: "",
              subbuildingname: "",
              buildingname: "",
              number: "",
              premise: "",
              street: "",
              posttown: "",
              county: "",
              postcode: "",
            },
            giftAid: this.otherObj.giftAid,
            country: this.otherObj.country,
            whatsapp: true,
            post: true,
            emailCheck: true,
            sms: true,
          };

          this.elementsArray[ind].card.clear();
        }
      }
    },
    checkSub(item) {
      if (item.actionType == "subproject") {
        this.showSubs = true;
        let q = { project: item.id };
        if (this.$route.query.type) q.type = this.$route.query.type;
        if (this.$route.query.tID) q.tID = this.$route.query.tID;
        if (this.$route.query.sf) q.sf = this.$route.query.sf;
        this.$router.push({
          path: this.$route.path,
          query: q,
        });
      }
    },
    backSub() {
      let q = {};
      if (this.$route.query.type) q.type = this.$route.query.type;
      if (this.$route.query.tID) q.tID = this.$route.query.tID;
      if (this.$route.query.sf) q.sf = this.$route.query.sf;
      this.$router.replace({ path: this.$route.path, query: q });
    },
    getTitle(items) {
      let obj = items.find((el) => el.id == this.$route.query.project);
      return obj ? obj.title : "Projects";
    },
    getRoute(item, val) {
      // console.log(item, val);
      return item.actionType == "url"
        ? item.url
        : item.actionType == "page" && item.pageID != this.$route.params.id
        ? `/page/${item.pageID}` +
          (val != ""
            ? `?type=${val}&tID=${item.id}` +
              (item.saleforceID
                ? `&sf=${item.saleforceID}`
                : val == "p"
                ? `&sf=${this.projectID}`
                : `&sf=${this.fundID}`)
            : "")
        : "#";
    },
    getDays(val) {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let valid = new Date(val);
      valid.setHours(0, 0, 0, 0);

      let diff = valid.getTime() - currentDate.getTime();
      let oneDay = 24 * 60 * 60 * 1000;

      let daysLeft = 0;
      if (diff > 0) {
        daysLeft = Math.ceil(diff / oneDay);
      }
      return daysLeft;
    },
    getProgress(item) {
      return (item.collectedAmount / item.fundAmount) * 100;
    },
    loadData() {
      this.loading = true;
      this.$emit("page-loading", true);
      this.$emit("set-sticky", false);
      var config = {
        method: "get",
        url:
          "https://worldappeal.appick.io/api/Page/LoadWebNew?id=" +
          this.$route.params.id,
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((response) => {
          this.pageData = response.data.data;
          this.pageData.forEach((el) => {
            el.content = JSON.parse(el.content);
          });
          if (this.pageData.length > 0 && this.pageData[0].type != "Hero") {
            this.$emit("set-sticky", true);
          }
          this.elementsArray = new Array(this.pageData.length).fill({
            el: null,
            card: null,
          });
          // console.log(this.pageData);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          if (this.pageData.length > 0) {
            this.$emit("page-loading", false);
          }
        });
    },
    loadSub() {
      if (this.$route.query.project) {
        this.showSubs = true;
        this.subLoading = true;
        var config = {
          method: "get",
          url:
            "https://worldappeal.appick.io/api/Project/LoadSubs?id=" +
            this.$route.query.project,
          headers: {
            Authorization: "bearer " + this.$store.state.token,
          },
        };
        axios(config)
          .then((response) => {
            this.currentData = response.data.data;
            // console.log(this.currentData);
          })
          .catch(function (error) {
            this.currentData = [];
            console.log(error);
          })
          .finally(() => {
            this.subLoading = false;
          });
      } else {
        this.showSubs = false;
        this.currentData = [];
      }
    },
    saveT(obj) {
      var config = {
        method: "post",
        url: "https://worldappeal.appick.io/api/transactions/save",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
        data: obj,
      };
      axios(config)
        .then((response) => {
          // console.log(response);
          this.transObjOther = {};
          this.transObjP = {};
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getFund(id, amount) {
      var config = {
        method: "get",
        url: "https://worldappeal.appick.io/api/Fundraiser/" + id,
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          this.updateFund(res.data.data, amount);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateFund(val, amount) {
      val.collectedAmount = val.collectedAmount + amount;
      val.donationCount = val.donationCount + 1;

      var config = {
        method: "put",
        url: "https://worldappeal.appick.io/api/Fundraiser/" + val.id,
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
        data: val,
      };
      axios(config)
        .then((res) => {
          // console.log(res);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    contactSubmit(type) {
      // console.log(this.mailObj);
      let text = `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPhone: ${this.mailObj.phone}\nSubject: ${this.mailObj.subject}\nMessage: ${this.mailObj.message}`;
      let subject = "World Appeal | Contact";
      if (type == "fundraising") {
        text = `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPhone: ${this.mailObj.phone}\nAddress: ${this.mailObj.address}\nFundraising Plans: ${this.mailObj.message}`;
        subject = "World Appeal | Fundraising Plans";
      } else if (type == "volunteer") {
        subject = "World Appeal | Volunteer";
        text = `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPhone: ${this.mailObj.phone}\nJob: ${this.mailObj.job}\nExperience: ${this.mailObj.message}`;
      }
      // console.log(text, subject);

      this.request = true;

      const config = {
        method: "post",
        url: "https://mail.appick.io/cloudious",
        data: {
          emailTo: this.receiver,
          subject: subject,
          text: text,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            this.toast.success("Message sent successfully!");
            this.mailObj = {
              name: "",
              email: "",
              message: "",
              address: "",
              phone: "",
              job: "",
              subject: "",
            };
          } else {
            this.toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.toast.error("Something went wrong!");
        })
        .finally(() => (this.request = false));
    },
  },
};
</script>

<style lang="css">
.splide__list {
  /* transition: all 1s ease 0s; */
  transition: transform 900ms ease 0s;
}

.splide__pagination {
  bottom: unset;
  position: static;
  margin-top: 10px;
}
.splide__pagination__page {
  background-color: rgba(34, 34, 34, 0.4);
}
.splide__pagination__page.is-active {
  background: #6a2875;
  transform: none;
}

.splide__arrow {
  background: transparent;
}
.splide__arrow svg {
  fill: hsla(0, 0%, 93.3%, 0.9);
}

.storybottomslider .splide__list {
  padding-bottom: 10px !important;
}

/* ==== */
.storybottomslider .splide__slide {
  padding-inline: 5px !important;
}
.storybottomslider .splide__arrows {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0px;
}
.storybottomslider .splide__arrow svg {
  fill: #666;
}
.storybottomslider .splide__arrow:disabled svg {
  fill: #999;
}
.storybottomslider .splide__arrow {
  /* === */
  /* top: calc(50% - 18px); */
  position: static;
  transform: none;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  background: white;
  opacity: 1;
  width: 2.3rem;
  height: 2.3rem;
}

#main-slider .splide__list {
  /* === */
  /* min-height: 73vh; */
  min-height: 85vh;
}
#main-slider .splide__pagination {
  position: absolute;
  bottom: 65px;
  height: 40px;
}
#main-slider .splide__pagination__page {
  background: rgba(255, 255, 255, 0.4);
}
#main-slider .splide__pagination__page.is-active {
  background: #6a2875;
  transform: scale(1.4);
}

.my-lightbox .splide__track {
  height: 100%;
}
.my-lightbox .splide__arrow svg {
  width: 4rem;
  height: 4rem;
}
.my-lightbox-thumbnail .splide__track--nav > .splide__list > .splide__slide {
  opacity: 1;
}
.my-lightbox .splide__track--nav > .splide__list > .splide__slide.is-active {
  border-color: transparent;
  opacity: 0.3;
}

.setRight {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .setRight {
    margin-left: 0;
  }

  .my-lightbox .splide__arrow {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  #main-slider .splide__pagination {
    bottom: 45px;
  }
}
</style>
